.ContenedorCardEstud {
  background-color: white;
  padding: .5% 1%;
  margin-bottom: .5%;
  display: flex;
  align-items: center;
  gap: 1%;

  p {
    margin: 0;
    padding: 0;
  }
}

.CardEstudExpediente {
  background-color: white;
  padding: 1.5% 1%;
  margin-bottom: .5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1%;

  p {
    margin: 0;
    padding: 0;
  }
}

.ImgEstudiosCard {
  width: 40px;
  height: 40px;
  overflow: hidden;
  object-fit: cover;
  object-position: center;
  background-color: #d6d6d6;
  border-radius: 5rem;
}

.ContenedorCardEstud:hover {
  background-color: #dde3e6;
  cursor: pointer;
}

.CardEstudExpediente:hover {
  background-color: #dde3e6;
  cursor: pointer;
}

.overflow-ellipsis {
  white-space: nowrap;
  /* Evita el salto de línea */
  overflow: hidden;
  /* Oculta el contenido desbordado */
  text-overflow: ellipsis;
  /* Muestra los puntos suspensivos */
  width: 200px;
  /* Ancho fijo del contenedor */
  font-weight: 500;
  color: #424242;
}