.BodyNewExped{
  width: 100%;
  overflow: hidden;
  overflow-y: scroll;
  max-height: calc(98vh - 52px);
}
.BodyNewExped::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: #d6d6d6;
}
.BodyNewExped:hover::-webkit-scrollbar {
  display: initial;
}
.BodyNewExped::-webkit-scrollbar-thumb {
  background-color: rgb(96, 99, 102);
  border-radius: 1rem;
  height: 1px;
}

// Pestañas expediente
.ExpedNewTabs {
  background: #fff;
  width: 100%;
  text-align: center;
  ul {
    padding: 0 3%;
    list-style: none;
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 5%;
    width: 100%;
  }
  li {
    // width: 200px;
    text-align: center;
    padding: 0.1%;
  }
  li:hover {
    cursor: pointer;
    border-bottom: 2px solid rgb(150, 189, 199);
    color: rgb(150, 189, 199);
    font-weight: 500;
  }
  .liSelected {
    cursor: pointer;
    border-bottom: 2px solid rgb(20, 143, 159);
    color: rgb(20, 143, 159);
    font-weight: 500;
  }
}
.ExpedNewTabs::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: #d6d6d6;
}
.ExpedNewTabs:hover::-webkit-scrollbar {
  display: initial;
}
.ExpedNewTabs::-webkit-scrollbar-thumb {
  background-color: rgb(96, 99, 102);
  border-radius: 1rem;
  height: 1px;
}

// Acordion de expediente
.AcordionExpedNew{
  width: 100%;
  background-color: #fff;
  padding: 1%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d7d7d7;
  border-radius: .5rem .5rem 0 0;
  cursor: pointer;
}
.MArgenAbajo{
  margin-bottom: 1%;
}
.CompAcordion{
  margin: 0;
  border-radius: 0;
}
.hithe{
margin-top: 150px;
}

@media (max-width: 800px) {
  .ExpedNewTabs {
    overflow: hidden;
    overflow-x: scroll;
    ul {
      width: 153vw;
    }
  }

}
