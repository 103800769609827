.GeneralGridAsistentes{
width: 100%;
max-height: calc(97vh - 52px);
}


.GeneralContenedorAsitentes{
    margin: .5%;
}
.EncavezadoAsistentes{
    padding: 1% 3%;
    background-color: #fff;
}
.AcomodoEncavezado{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1%;
}
.imgAddAsist{
    width: 16px;
    height: 16px;
    cursor: pointer;
   
}
.imgDetalleAsist{
    width: 40px;
    height: 40px;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
    background-color: #d7d7d7;
    border-radius: 5rem;
}
.TablaAsistentes{
    margin: .5%;
    min-height: calc(77vh - 52px);
    max-height: calc(83vh - 52px);
    overflow: hidden;
    overflow-y: scroll;
}
.DetalleEditAsistent{
 background-color: #fff;
//  margin: 1% 0;

//  padding: .5% 2%;
}
.EncabAsistente{
    background-color: #148f9f;
    color: #fff;
    padding: 1%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.DetalleContenidoAsist{
    padding: 1% 2%;
    height: 72vh;
    overflow: hidden;
    overflow-y: scroll;
    width: 50vw;
}

.AcordionAsist{
    display: flex ;
    justify-content: space-between;
    align-items: center;
    padding: 1%;
    border-bottom: 1px solid #d7d7d7;
    cursor: pointer;
}
.AcordionAsist:hover{
    background-color: #f4f7f7;
    border-radius: .5rem;
}


.DateAsistent{
    width: 100%;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #d7d7d7;
}
.OpPermisosAsist{
    display: flex; 
    justify-content: space-between; 
    padding: 1% 5%; 
    span{
        margin-left: 3px;
    }
    input{
        width: 16px;
        height: 16px ;
    }
}

.InputySpanflex{
    display: flex;
    align-items: center;
}

.ContenidoHorarioCalendarioAsist{
    width: 100%;
    .InputySpanflexDos{
        padding: 1%;
        display: flex;
        align-items: center;
        input{
            width: 16px;
            margin-right: 3px;
        }
    }
}
.AsistHorarioCalendario{
    display: flex;
    overflow: hidden;
    overflow-x: scroll;
    max-width: calc(100vw - 52px);
    border-bottom: 1px solid #d6d6d6;
}




// css scroll
.DetalleContenidoAsist::-webkit-scrollbar {
    
    width: 3px;
    height: 3px;
    background-color: #d6d6d6;
  }
  .DetalleContenidoAsist:hover::-webkit-scrollbar {
    display: initial;  
  }
  .DetalleContenidoAsist::-webkit-scrollbar-thumb {
    background-color: rgb(96, 99, 102);
    border-radius: 1rem;
   height: 1px;
  }
  .AsistHorarioCalendario::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  .AsistHorarioCalendario:hover::-webkit-scrollbar {
    display: initial;  
  }
  .AsistHorarioCalendario::-webkit-scrollbar-thumb {
    background-color: #148f9f;
    border-radius: 1rem;
   height: 1px;
  }
  
//   Tabla asistentes
.TablaAsistentes::-webkit-scrollbar {
    
    width: 3px;
    height: 3px;
    background-color: #d6d6d6;
  }
  .TablaAsistentes:hover::-webkit-scrollbar {
    display: initial;  
  }
  .TablaAsistentes::-webkit-scrollbar-thumb {
    background-color: rgb(96, 99, 102);
    border-radius: 1rem;
   height: 1px;
   
  }
  .contCardModal{
    border: #d5d5d5;
    border-top: 1px solid #d5d5d5;
    background-color: #eaeef0;
    padding: .5%;
  
  }

  @media (max-width: 800px) {
    .DetalleContenidoAsist{
        height: 60vh;
        width: 90vw;
    }
  }