
  .TopModalNewEstud{
   width: 100%;
   padding: 1% 2%;
   color: white;
   display: flex;
   align-items: center;
   justify-content: space-between;
   background-color: #148F9F;
  }
  .MidModalEstud{
    width: 40vw;
    max-height: calc(72vh - 52px);
    min-height: 40vh;
    overflow: hidden;
    overflow-y: scroll;
    padding: 2%;    
  }
  .MidModalEstud::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    background-color: #d6d6d6;
  }
  .MidModalEstud:hover::-webkit-scrollbar {
    display: initial;
  }
  .MidModalEstud::-webkit-scrollbar-thumb {
    background-color: rgb(96, 99, 102);
    border-radius: 1rem;
    height: 1px;
  }
  .BtnEstudExped{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1%;
    width: 100%;
  }

  @media (max-width: 800px) {
    .MidModalEstud{
      width: 90vw;
    }
  }
  @media (max-width: 320px) {
    .MidModalEstud{
      height: 70vh;
    }
  }