.ContainerVinculacion {
  background-color: #eaeef0;
  padding: 0.5%;
}


.ContEncabezadovincul {
  padding: 1% 3%;
  background-color: white;
  margin-bottom: 0.5%;
}

.Encabesado-vinculacion {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1%;
}

// Aqui empieza la tabla
.contenido-solisitudes {
  height: calc(80vh - 52px);
  overflow: hidden;
  overflow: scroll;
  overflow-y: scroll;
  overflow-x: hidden;
}

//Detalle de vinculacion
.TopDetalleModalVincu {
  display: flex;
  background-color: #148f9f;
  justify-content: space-between;
  padding: 1% 2%;

  b {
    color: white;
  }
}

.VinculacionDetalle {
  background-color: #fff;

  .imgDetalleVincul {
    margin-top: 3%;
    text-align: center;
  }

  .imgDetalleVincul img {
    background-color: #e2e2e2;
    padding: "1%";
    border-radius: 50rem;
    width: 50px;
    height: 50px;
  }

  .informacion {
    width: 100%;
  }
}

// Botones de modal
.BtnsVincul{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 35px;
    gap: 1%;
    padding: 1%;
    margin-top: 1%;
  }




// Css de scrolles detalle
.informacion::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: #d6d6d6;
}

.informacion:hover::-webkit-scrollbar {
  display: initial;
}

.informacion::-webkit-scrollbar-thumb {
  background-color: rgb(96, 99, 102);
  border-radius: 1rem;
  height: 1px;
}

//   css Contenido solisitudes scrolles
.contenido-solisitudes::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: #d6d6d6;
}

.contenido-solisitudes:hover::-webkit-scrollbar {
  display: initial;
}

.contenido-solisitudes::-webkit-scrollbar-thumb {
  background-color: rgb(96, 99, 102);
  border-radius: 1rem;
  height: 1px;
}

//   contenedor de modal Agregar
.contCardModal {
  height: 55vh;
  width: 100%;
  overflow: scroll;
  overflow-x: hidden;
}

//   css Contenido solisitudes scrolles
.contCardModal::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: #d6d6d6;
}

.contCardModal:hover::-webkit-scrollbar {
  display: initial;
}

.contCardModal::-webkit-scrollbar-thumb {
  background-color: rgb(96, 99, 102);
  border-radius: 1rem;
  height: 1px;
}
.ModalwidthVincu {
  width: 45vw;
  background-color: white;
  height: 100%;
  padding: 1%;
  height: 55vh;
  overflow: hidden;
  overflow-y: scroll;
}
.ModalwidthVincu::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: #d6d6d6;
}

.ModalwidthVincu:hover::-webkit-scrollbar {
  display: initial;
}

.ModalwidthVincu::-webkit-scrollbar-thumb {
  background-color: rgb(96, 99, 102);
  border-radius: 1rem;
  height: 1px;
}

@media (max-width: 800px) {
  .ModalwidthVincu {
    width: 90vw;
    height: 67vh;
  }
  .BtnsVincul{
    display: block;
    
  }

.ButtonVincula {
  width: 100%;
}
.contCardModal {
  max-height: calc(56vh - 52px);
}
}
