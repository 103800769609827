$light: #ffff;
$gray: #c4ccde;

.Fondo {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.445);
    // display: none;
    justify-content: center;
    align-items: center;
}

.sidebar {
    top: 0;
    left: 0;
    width: 75%;
    height: 100vh;
    // overflow: scroll;
    // overflow-x: hidden;
    background-color: #148f9f;
    padding: .2%;
    position: absolute;
    z-index: 2;

    span {
        font-size: 90%;
        color: white;
    }

    ul {
        padding-left: 0rem;
        list-style: none;
        color: white;

        li {
            //    padding-top: 11%;
            //    padding-bottom: 4%;
            //    padding-left: 5%;
            //    padding-right: 5%;

            a,
            link {
                text-decoration: none;
                color: $light;
                font-size: 100%;
                cursor: pointer;
            }
        }
    }

    li:hover {
        background-color: #117f8d;
    }

    img {
        width: 21px;
        margin-right: 5px;
    }
}

.drawerStyle {
    span {
        font-size: 90%;
        color: white;
    }

    ul {
        padding-left: 0rem;
        list-style: none;
        color: white;

        li {
            //    padding-top: 11%;
            //    padding-bottom: 4%;
            //    padding-left: 5%;
            //    padding-right: 5%;

            a,
            link {
                text-decoration: none;
                color: $light;
                font-size: 100%;
                cursor: pointer;
            }
        }
    }

    li:hover {
        background-color: #117f8d;
    }

    img {
        width: 21px;
        margin-right: 5px;
    }
}

.sidebar::-webkit-scrollbar {

    width: 3px;
    height: 3px;
    background-color: #d6d6d6;
}

.sidebar:hover::-webkit-scrollbar {
    display: initial;
}

.sidebar::-webkit-scrollbar-thumb {
    background-color: rgb(96, 99, 102);
    border-radius: 1rem;
    height: 1px;

}

.LinkAcomodo {
    display: flex;
    align-items: center;
    gap: 1%;
    padding-top: 11%;
    padding-bottom: 4%;
    padding-left: 5%;
    padding-right: 5%;
}

@media (min-width: 800px) and (max-width: 3000px) {
    // .css-dev-only-do-not-override-1km3mtt{
    //     display: none;
    // }

    // .css-1km3mtt{
    //     display: none;
    // }
}