.ModalAddOrden {
  width: 50vw;
  max-height: calc(75vh - 52px);
  overflow: hidden;
  overflow-y: scroll;
  padding: 3%;
}

.ModalAddOrden::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: #d6d6d6;
}

.ModalAddOrden:hover::-webkit-scrollbar {
  display: initial;
}

.ModalAddOrden::-webkit-scrollbar-thumb {
  background-color: rgb(96, 99, 102);
  border-radius: 1rem;
  height: 1px;
}

.filaDatosMedicos {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.ContenedorCardOrdenes{
  background-color: white;
  padding: .5% 1%;
  margin-bottom: .5%;
  display: flex;
  align-items: center;
  gap: 1%;
  p{
    margin: 0;
    padding: 0;
  }
}

.ContenedorCardOrdenes:hover{
  background-color: #dde3e6;
  cursor: pointer;
}

@media (max-width: 800px) {
  .ModalAddOrden {
    width: 80vw;
  }

  .filaDatosMedicos {
    display: block;
  }

}