.modalDetalle{
   position: fixed;
   z-index: 999;
   top: 0;
   left: 0;
   width: 100%;
   min-height: 100vh;
   display: none;
   justify-content: center;
   align-items: center;
   
}
.modalDetalle.is-open{
    display: flex;
}
 .modalDetalle-container{
    position: relative;
    background-color: white;
    border-radius: .1rem;
   // max-height: clac(100vh - 1rem);
   // max-width: clac(100vw - 1rem);
   //  margin: 0 2%;
 
 }
.DetalModalFil{
   width: 30vw;
}
@media (max-width: 800px) {
   .DetalModalFil{
      width: 90vw;
      
   }  
}