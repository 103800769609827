
.CardVinculacion{
    width: 100%; 
    background-color: #ffff;
    margin-bottom: .2rem;
    padding:.5% 1%;
    .imgVinculCard {
        width: 40px;
        height: 40px;
        background-color: #dadcdd;
        border-radius: 5rem;
        overflow: hidden;
        }
        .UsuVinc{
        p{
            padding: 0;
            margin: 0;
        }      
    }
}
.CardVinculacion:hover {
    background-color: rgb(217, 220, 224);
    .imgVinculCard{
        background-color: white;
    }
}
