.contenedoprin {
  background-color: #eaeef0;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  word-break: break-all;
  padding: .5%;
}

.Encabezadorec {
  background-color: #fff;
  width: 100%;
  padding: 1% 3%;

  .Encab2rec {
    display: flex;
    justify-content: space-between;

    a {
      color: #148f9f;
      cursor: pointer;
    }
  }

  .contrec {
    padding: 1%;
    text-align: center;
    width: 15%;
    cursor: pointer;
    box-sizing: content-box;
    position: relative;
    outline: none;
  }

  .active-contrec {
    background: #ffffff;
    color: #148f9f;
  }
}

.espaciadorec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1%;
}

.reccard {
  max-height: calc(78vh - 52px);
  overflow: hidden;
  overflow-y: scroll;
  margin-top: .5%;
}

.reccard::-webkit-scrollbar {

  width: 3px;
  height: 3px;
  background-color: #d6d6d6;
}

.reccard:hover::-webkit-scrollbar {
  display: initial;
}

.reccard::-webkit-scrollbar-thumb {
  background-color: rgb(96, 99, 102);
  border-radius: 1rem;
  height: 1px;
}

// imagen de detalle
.contenedorimg {
  display: flex;
  text-align: center;
  justify-content: center;
  margin: 4%;
  gap: 2%;

  img {
    width: 70px;
    height: 70px;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
    background-color: #d7d7d7;
    border-radius: 5rem;
    border: 2px solid #d7d7d7;
  }

}

.imgConfig {
  width: 45px;
  height: 45px;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  background-color: #d7d7d7;
  border-radius: 5rem;
  border: 2px solid #d7d7d7;
}

*,
::before,
::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.botonesrec {
  button {
    margin-top: .5%;
    border: none;
    background-color: none;
    color: #148f9f;
    padding: .5%;
    width: 120px;
  }

  button:hover {
    background-color: #148f9f;
    color: rgb(255, 255, 255);
  }
}


.contenido {
  padding: .5% 0;
  width: 100%;
  height: 100%;
  display: none;
}

.detallesrec {
  width: 50vw;
  overflow: hidden;
  overflow-y: scroll;
  height: 72vh;
  display: block;
}

.detallesrec::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: #d6d6d6;
}

.detallesrec:hover::-webkit-scrollbar {
  display: initial;
}

.detallesrec::-webkit-scrollbar-thumb {
  background-color: rgb(96, 99, 102);
  border-radius: 1rem;
  height: 1px;
}

.Topdetail {
  display: flex;
  justify-content: space-between;
  background-color: #148f9f;
  color: white;
  padding: 1% 2%;
  b{
    color: white;
  }
}

.medlista {
  text-Align: left;
  padding: 1% 5%;
}

.contactosrec {
  height: 200px;
  width: 100%;
  overflow: scroll;
  overflow-x: hidden;
}

//   css Contenido solisitudes scrolles
.contactosrec::-webkit-scrollbar {

  width: 3px;
  height: 3px;
  background-color: #d6d6d6;
}

.contactosrec:hover::-webkit-scrollbar {
  display: initial;
}

.contactosrec::-webkit-scrollbar-thumb {
  background-color: rgb(96, 99, 102);
  border-radius: 1rem;
  height: 1px;
}

.FirmaImg{
  width: 35%;
}
.ModalStyleRlista{
  width: 30vw;
  padding: 1%;
}

@media (max-width: 800px) {
  .ModalStyleRlista{
    width: 90vw;
  }
  .detallesrec{
    width: 90vw;
    
  }
  .FirmaImg{
    width: 50%;
  }
}