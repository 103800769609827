.GenaralScroll {
  overflow: hidden;
  overflow-y: scroll;
  height: calc(100vh - 52px);
}

.GenaralScroll::-webkit-scrollbar {

  width: 3px;
  height: 3px;
  background-color: #d6d6d6;
}

.GenaralScroll:hover::-webkit-scrollbar {
  display: initial;
}

.GenaralScroll::-webkit-scrollbar-thumb {
  background-color: rgb(96, 99, 102);
  border-radius: 1rem;
  height: 1px;
}

.ContenedorCabezeraMetodos {
  // margin: .5%;
  background-color: white;
  padding: 1% 3%;
}

.CabezeraMetodos {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1%;

  img {
    width: 20px;
  }

  span {
    font-weight: 500;
    font-size: 120%;
  }
}

.TablaPuntoDeventa {
  margin: .5%;
}

.FlexAliniado {
  display: flex;
  align-items: center;
  gap: 1%;
}

// Modales css
.CabezeraModal {
  justify-content: space-between;
  background-color: #148f9f;
  padding: 1% 2%;

  span {
    // font-weight: 500;
    color: white;
  }
}

// detalle modal
.ImgPerfilPV {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 5rem;
  object-fit: cover;
  object-position: center;
}

.margen1Pv {
  font-weight: 500;
  text-align: right;
  border-bottom: 1px solid #d7d7d7;
  padding: 1% 0;
}

.PieModalDetale {
  margin-top: 1%;
  padding-bottom: 1%;
  border-bottom: 1px solid #424242;
  border-top: 1px solid #424242;

  p {
    font-weight: 500;
  }
}

.GeneralModalPV {
  padding: 2%;
  width: 40vw;
  height: 70vh;
  overflow: hidden;
  overflow-y: scroll;
}


.GeneralModalPV::-webkit-scrollbar {

  width: 3px;
  height: 3px;
  background-color: #d6d6d6;
}

.GeneralModalPV:hover::-webkit-scrollbar {
  display: initial;
}

.GeneralModalPV::-webkit-scrollbar-thumb {
  background-color: rgb(96, 99, 102);
  border-radius: 1rem;
  height: 1px;

}
.GeneralModalPV2 {
  padding: 2%;
  width: 40vw;
  height: 70vh;
  overflow: hidden;
  overflow-y: scroll;
}
.GeneralModalPV2::-webkit-scrollbar {

  width: 3px;
  height: 3px;
  background-color: #d6d6d6;
}

.GeneralModalPV2:hover::-webkit-scrollbar {
  display: initial;
}

.GeneralModalPV2::-webkit-scrollbar-thumb {
  background-color: rgb(96, 99, 102);
  border-radius: 1rem;
  height: 1px;

}
.ModalSeleContacPv{
  padding: 2%;
  width: 40vw;
  overflow: hidden;
  overflow-y: scroll;
}
.ModalSeleContacPv::-webkit-scrollbar {

  width: 3px;
  height: 3px;
  background-color: #d6d6d6;
}

.ModalSeleContacPv:hover::-webkit-scrollbar {
  display: initial;
}

.ModalSeleContacPv::-webkit-scrollbar-thumb {
  background-color: rgb(96, 99, 102);
  border-radius: 1rem;
  height: 1px;

}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}




.TablaDeDetalle{
  width: 100%;
  overflow: hidden;
  overflow-x: scroll;
}
.TablaDeDetalle::-webkit-scrollbar {

  width: 3px;
  height: 3px;
  background-color: #d6d6d6;
}

.TablaDeDetalle:hover::-webkit-scrollbar {
  display: initial;
}

.TablaDeDetalle::-webkit-scrollbar-thumb {
  background-color: rgb(96, 99, 102);
  border-radius: 1rem;
  height: 1px;

}
@media (max-width: 800px) {
  .GeneralModalPV {
    width: 90vw;
    
  }
   .GeneralModalPV2 {
    width: 90vw;
    height: 50vh;  
  }
  .ModalSeleContacPv{
    width: 90vw;
  }
}
