.RowInfoPerfil{
    width: 100%;
    display: flex;
    justify-content: space-between;
    input{
        width: 200px;
      }
     .ant-select{
        width: 200px;
      }
      .ant-picker{
        width: 200px;
      }
} 
.BotonesPerfil{
  display: flex;
  margin: 2% 0;
  gap: 1%;
  justify-content: right;

}
.Container2{
    background-color:#fff; 
    padding:1%;

    .buton{
        padding:1%;
        width: 5rem; 
        color:#148f9f;
        border:2px solid #148f9f;
        border-radius: 5rem;
    }
    .buton:hover{
        margin-left:10px; 
        color: #fff;
        background-color: #148f9f;
    }
}

.PerfilImg{
    width:50px;
    height:50px;
    margin-right:10px;
    border:2px solid #d7d7d7;
    border-radius:.5rem;
    overflow: hidden;
    object-fit: cover;
}

.PerfilImgEdit{
    width:50px;
    height:50px;
    margin-right:10px;
    border:2px solid #d7d7d7;
    border-radius:.5rem;
    overflow: hidden;
    object-fit: cover;
    cursor: pointer;
}
.PerfilImgEdit:hover{
    border-color: #7e8383;
}

.CedulasPerfil{
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1%;
}


@media (max-width: 800px) {
    .RowInfoPerfil{
        width: 100%;
        display: block;
      input{
        width: 100%;
      }
      .ant-select{
        width: 100%;
      }
      .ant-picker{
        width: 50%;
      }
    }
    .BotonesPerfil{
      justify-content: right;
 
    }
    .CedulasPerfil{
      display: block;
      gap: 1%;
    }
}