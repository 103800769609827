.contenedorprinAnun{
  background-color: #eaeef0;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  word-break: break-all;
  padding: 1%;
}

.EncabezadoA{
    background-color: #fff;
    align-items: center;
    width: 100%;
    padding: 1%;
}

.espaciadoA{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

.containerAnun{
    margin-top: .5%;
    border: none;
    background-color: none;
    color: #148f9f;
    padding: .5%;
    text-align: center;
    width: 15%;
    cursor: pointer;
    box-sizing: content-box;
    position: relative;
    outline: none;
  
  }
  .active-containerAnun{
    background: #ffffff;
    color: #148f9f;
  }

  .containerAnun:hover {
    background-color: #148f9f;
    color: rgb(255, 255, 255);
  }

.Anuncioscard{
  max-height: calc(78vh - 52px);
  overflow: hidden;
  overflow-y: scroll;
  margin-top: .5%;
}

.Contenedorbtn{
    display: flex;
    gap: 1%;
    button {
        margin-top: .5%;
        border: none;
        background-color: none;
        color: #148f9f;
        padding: .5%;
        width: 120px;
    }
    
    button:hover{
        background-color: #148f9f;
        color: rgb(255, 255, 255);
    }
}

.Anuncioscard::-webkit-scrollbar {
    
  width: 3px;
  height: 3px;
  background-color: #d6d6d6;
}
.Anuncioscard:hover::-webkit-scrollbar {
  display: initial;  
}
.Anuncioscard::-webkit-scrollbar-thumb {
  background-color: rgb(96, 99, 102);
  border-radius: 1rem;
 height: 1px;
}

.TopdetailAnun{
    display: flex;
    justify-content: space-between;
    background-color: #148f9f;
    span{
      color: white;
      padding: 2% 2.5%;
    }
  }

  .ContenedormodalA{
    width: 40vw;
    height: 60vh;
    overflow: hidden;
    overflow-y: scroll;
    text-align: center;
  }
  .ContenedormodalA::-webkit-scrollbar {
    
    width: 3px;
    height: 3px;
    background-color: #d6d6d6;
  }
  .ContenedormodalA:hover::-webkit-scrollbar {
    display: initial;  
  }
  .ContenedormodalA::-webkit-scrollbar-thumb {
    background-color: rgb(96, 99, 102);
    border-radius: 1rem;
   height: 1px;
  }

  .imgDetalleAnuncio{
    width: 60px;
    height: 60px;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
    background-color: #d7d7d7;
    border-radius: 5rem;
    border: 2px solid #d7d7d7;
  }
  @media (max-width: 800px) {
    .ContenedormodalA{width: 90vw;}
  }