.ObejoFile{
    width: 300px;
    padding: 1%;
    gap: 10px;
    border: 1px solid #d7d7d7;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin: 1%;
}