* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;

}


// .App{
//   max-height: calc(99vh - 52px);
// }

@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Noto+Sans+JP:wght@500&display=swap');

.RowClasButton{ 
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
 
}

.GeneralNoImage{
  background-image: url("Img//no-image.png") ;
  background-color: #d7d7d7;
  object-position: center;
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: content-box;
}

.GeneralBoton {
  background-color: #148f9f;
  cursor: pointer;
  border-color: #148f9f;
  color: white;
  border: none;
  margin: 1% 0;
}

.GeneralBotonWhite {
  background-color: white;
  cursor: pointer;
  border-color: #148f9f;
  color: #148f9f;
  border: 1.5px solid #148f9f;
}

.GeneralBotonWhite:hover {
  background-color: #148f9f;
  border-color: #148f9f;
  color: white;
}

.Filtro3Botones {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 1%;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #148f9f;
    cursor: pointer;
    border-color: #148f9f;
    height: 2rem;
    color: white;
    border: none;
    width: 100%;
    border-radius: .2rem;
    margin: 1% 0;
  }
}

.TopModal {
  width: 100%;
  color: white;
  background-color: #148f9f;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1% 2%;

  .x {
    cursor: pointer;
  }
}

.ModalListCfiltro {
  padding: 2%;
  width: 45vw;
  min-height: 40vh;

}
.ModalChat {
 width: 60vw;
 height: 72vh;
 overflow: hidden;
 overflow-y: auto;
 background-color: #eaeef0;
 position: relative;
}
.ModalChat::-webkit-scrollbar {

  width: 3px;
  height: 3px;
  background-color: #d6d6d6;
}

.ModalChat:hover::-webkit-scrollbar {
  display: initial;
}

.ModalChat::-webkit-scrollbar-thumb {
  background-color: rgb(96, 99, 102);
  border-radius: 1rem;
  height: 1px;
}

.ModalConScroll {
  padding: 2%;
  width: 45vw;
  height: 60vh;
  overflow: hidden;
  overflow-y: scroll;
}

.ModalConScroll::-webkit-scrollbar {

  width: 3px;
  height: 3px;
  background-color: #d6d6d6;
}

.ModalConScroll:hover::-webkit-scrollbar {
  display: initial;
}

.ModalConScroll::-webkit-scrollbar-thumb {
  background-color: rgb(96, 99, 102);
  border-radius: 1rem;
  height: 1px;
}

.cardContactosModals {
  background-color: white;
  cursor: pointer;
  // border-bottom: 1px solid #d5d5d5;
  margin: .9% 0;

  img {
    background-color: #dde3e6;
    border-radius: 5rem;
    width: 45px;
    height: 45px;
  }
}

.cardContactosModals:hover {
  background-color: #dde3e6;

  img {
    background-color: white;
  }
}

.containerCardContactosModals{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1% 2%;
 }

 .contentCardContactosModals{ 
  display: flex;
  width: 100%;
  align-items: center;
  gap: 1%;
  p{
    margin-right: 30px;
  }
  
}

.drawerRegistro{
  // .ant-drawer-content-wrapper{
  //   width: 50%;
  // }
  // .ant-drawer-content-wrapper{
  //   width: 50%;
  // }

  width: 50%;
}

// .ant-drawer{
//   width: 70%;
// }

@media (max-width: 800px) {
  .ModalListCfiltro {
    width: 90vw;
  }

  .ModalConScroll {
    width: 90vw;
    height: 72vh;
  }
  .ModalChat {
    width: 90vw;  
  }
}
