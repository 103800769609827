.cardFactura{
    padding: 1%;
    background-color: white;
    margin-bottom: .5%;
    p{
        margin: 0;
    }

}
// .cardFactura:hover{
//     background-color: #dde3e6;
//     cursor: pointer;
// }
.PerfilImgfactura{
    width: 40px;
    height: 40px;
    background-color: #d7d7d7;
    border-radius: 5rem;
    overflow: hidden;
    object-fit: cover;
    object-position: center;
}