.BotonesHistorial{
    width: 100%;
    display: flex;
    gap: 1%; 
}

@media (max-width: 800px) {
    .BotonesHistorial{
      display: block;
    }
  }