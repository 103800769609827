
// Tabla de citas
.PrinCard{
    background-color: white;
    margin-top: .3%;
  }
  .CardNotas{ 
      display: flex;
      width: 100%;
      align-items: center;
      margin-top: .5%;
      padding: 1% 2%;
      cursor: pointer;
      justify-content: space-between;
      // p{
      //   margin-right: 30px;
      // }
      
  }

  .Container{
    display: flex;
    justify-content: space-between;
    .Opsiones{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
      img{
        width: 20px;
        height: 20px;
      }
    }
   }
  
  .active{
    background: "#000";
  }
  