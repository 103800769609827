.ContItemsdrowp{
    background-color: #f3f3f3;
    padding: .5% 0%;
}
.ItemDrowOption{
  gap: 10px;
    background-color: white;
    padding: 2%;
}
.ItemDrowOption:hover{
  background-color: rgb(239, 242, 243);

}
.imaglistadrowp{
    width: 25px; height: 25px;
    background-color: #d7d7d7;
    object-fit: cover;
    object-position: center;
    border-radius: 2rem;
}
.ScroList{
    min-height: calc(10vh - 52px);
    max-height: calc(30vh - 52px);
    overflow: hidden;
    overflow-y: scroll;
}

//   Input Buscar style
.input-wrapper {
    position: relative;
    width: 100%;
  }
  
  .input {
    box-sizing: border-box;
    width: 100%;
  }
  
  .input.password {
    padding: 2% 3% 2% 2%;
  }
  
  .input-icon {
    color: #191919;
    position: absolute;
    width: 15px;
    height: 15px;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .input-icon.password {
    left: unset;
    right: 12px;
  }

  // Scroll de lista de select detalle


.ScroList::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: #d6d6d6;
}
.ScroList:hover::-webkit-scrollbar {
  display: initial;  
}
.ScroList::-webkit-scrollbar-thumb {
  background-color: rgb(96, 99, 102);
  border-radius: 1rem;
 height: 1px;
}