
.CardChat{
    width: 100%; 
    background-color: #ffff;
    margin-bottom: .2rem;
    padding: .5%;
    .imgChatCard {
        width: 50px;
        height: 50px;
        background-color: #dadcdd;
        border-radius: 5rem;
        margin-right: 20px;
        overflow: hidden;
        }
   
}
.CardChat:hover {
    background-color: rgb(217, 220, 224);
    .imgChatCard{
        background-color: white;
    }
}