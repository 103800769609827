.ContenedorPrinCard{
    background-color: white;
    padding: 1%;
    margin: .5% 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%
  }

  .ContenedorPrinCard:hover{
    background-color: #dde3e6;
    .BgrImg{
      img{
        background-color: white;
      }
    }
  }
  
  .menutrespuntos{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .ContenedorCard{ 
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      p{
        margin: 0;
        padding: 0;
      }
  }
    