
.generalconfcitas{
    background-color: white;
    overflow-y: scroll;
    height: calc(97vh - 52px);
    padding: 1%;
}

.confcitas{
    background-color: white;
    padding: 1%;
}

.iconBack {
  width: 30px;
  cursor: pointer;
}
// .listafechas{
//     display: flex;
//     width: 100%;
//     align-items: center;
//     margin-top: 1%;
//     padding: 1% 1%;
// }

.listfecblock{
    border-radius: 10px 5%;
}


.generalconfcitas::-webkit-scrollbar {
    
    width: 3px;
    height: 3px;
    background-color: #d6d6d6;
  }
  .generalconfcitas:hover::-webkit-scrollbar {
    display: initial;  
  }
  .generalconfcitas::-webkit-scrollbar-thumb {
    background-color: rgb(96, 99, 102);
    border-radius: 1rem;
   height: 1px;
  }

  .BackConfigStyle{
    padding: 0 2.5%;
    display: flex;
    align-items: center;
    gap: .5rem;
    background-color: white;
  
    a{
      display: flex;
      align-items: center;
      padding: 0;  
      justify-content: left;
      margin: 0;
    }
  }