.ContEstudios {
    margin: .5%;
}


.TablaEstudios {
    margin-top: .5%;
    min-height: calc(77vh - 52px);
    max-height: calc(83vh - 52px);
    overflow: hidden;
    overflow-y: scroll;
}

// .DetalleEstudios{
//   // background-color: white;
// }
.contenidoLista {
    margin-top: .5%;
    // background-color: white;
    padding: .5%;
    height:72vh;
    width: 40vw;
    // height: 70%;
    padding: 1%;
    overflow: hidden;
    overflow-y: scroll;

    button {
        width: 100%;
        padding: 1%;
        background-color: #148f9f;
        color: #fff;
        border-radius: 5rem;
    }
}

.contenidoLista::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    background-color: #d6d6d6;
}

.contenidoLista:hover::-webkit-scrollbar {
    display: initial;
}

.contenidoLista::-webkit-scrollbar-thumb {
    background-color: rgb(96, 99, 102);
    border-radius: 1rem;
    height: 1px;
}

.botonesAcomodo {
    text-align: center;
    padding: 0 2%;

    button {
        width: 100%;
        padding: 1%;
        background-color: #148f9f;
        color: #fff;
        border-radius: 5rem;
    }
}

.SelectFantasma {
    padding: 3%;
    width: 100%;
    border: 1px solid #d7d7d7;
    border-radius: .2rem;
    background-color: white;
}

.SelectFantasmaActive {
    padding: 3%;
    width: 100%;
    border: 1px solid #d7d7d7;
    border-radius: .2rem;
    outline: 1px solid #00b7ff;
    box-shadow: 0 0 0 .2em #97b2c06c;
}

.DrowpOptions {
    border: 1px solid #d5d5d5;

}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2%;
    background-color: #148f9f;

    b {
        color: #fff;
    }
}

//  scroll Tabla estudios
.TablaEstudios::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    background-color: #d6d6d6;
}

.TablaEstudios:hover::-webkit-scrollbar {
    display: initial;
}

.TablaEstudios::-webkit-scrollbar-thumb {
    background-color: rgb(96, 99, 102);
    border-radius: 1rem;
    height: 1px;
}

//  scroll detalle
.DetalleEstAnalisis::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    background-color: #d6d6d6;
}

.DetalleEstAnalisis:hover::-webkit-scrollbar {
    display: initial;
}

.DetalleEstAnalisis::-webkit-scrollbar-thumb {
    background-color: rgb(96, 99, 102);
    border-radius: 1rem;
    height: 1px;
}

.imgDetalleEstud {
    width: 80px;
    height: 80px;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
    background-color: #d7d7d7;
    border-radius: 5rem;
    border: 2px solid #d7d7d7;
}

@media (max-width: 800px) {
    .contenidoLista  {
        width: 90vw;
        height: 72vh;
    }

}