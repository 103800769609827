
// Tabla de citas
.PrinCardEx{
  background-color: white;

  .Hora{
    margin-left: 1rem;
  }
  .Dia{
    font-size: 80%;
    color: #454545;
  }
}
.CardEx{ 
    display: flex;
    width: 100%;
    align-items: center;
    padding: .5% 2%;
    gap: 10px;
    // p{
    //   margin-right: 30px;
    // }
    
}
.PrinCardEx:hover{
  background-color: #dde3e6;
  .BgrImg{
    img{
      background-color: white;
    }
  }
}

.Ocultas{
  display: none;
}
.OpenOcultas{
  display: block;
}

.img2{
  width: 30px;
}
.ContainerEx{
  display: flex;
  justify-content: space-between;
 }
//Menu de Opsiones
.OpcionesExpe{
  cursor: pointer;
}
.OpcionesExpe:hover{
 color: rgb(20, 143, 159);
}

// Lista de Opsiones
 .DespleListaEx ul{display: none;}
 .DespleListaEx:hover ul{
  display: block;
  
  li:hover{
   background-color: #dde3e6;
  }
  }
  .perra{
    background-color: red;
  }

