.navegacion {
  background-color: #fff;
  padding: 0.5% 2%;
  font-size: 1.7rem;
  border-bottom: 0.1rem solid rgb(214, 214, 214);

  img {
    width: 20%;
  }
}

// .navegacion{
//     background-color: #fff;
//     padding: .5% 2%;
//     font-size: 1.7rem;
//     border-bottom: .1rem solid rgb(214, 214, 214);
//     display: flex;
//     align-items: center;
//     gap: 20%;
//     justify-content: space-between;
//     // img{
//     //     width: 20%;
//     // }
//     // width: 100%;

// }
.menu-navegacion {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.NavegacionFlex {
  display: flex;
  align-items: center;
  gap: 20%;
}

// .Item1Navegacion{}
.Menu-Hamburg {
  display: none;
}

.Menu_principal {
  width: 60%;
  display: flex;
  gap: 3%;
  justify-content: center;
  padding: 0%;
  text-decoration: none;

  li {
    list-style: none;
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    color: #464646;
    cursor: pointer;
  }

  a {
    text-decoration: none;
    color: #464646;
  }
}

.claseMenu {
  a {
    padding: 0.1rem 1.5rem;
    // padding-top: 0.1rem;
    // padding-left: 1.5rem;
    // padding-right: 1.5rem;
    // padding-bottom: 0.1rem;
    font-weight: 500;
    font-size: 17px;
    background-color: #f4f5f6;
    border-radius: 1rem;
    text-decoration: none;
    color: #148f9f;
  }
}

.ListaDeMenu {
  z-index: 999;

  a {
    list-style: none;
    text-decoration: none;
    width: 150px;
    cursor: pointer;
  }

  li {
    padding: 1% 4%;
    width: 150px;
    background-color: #fff;
    font-weight: 450;
    font-size: 16px;
    cursor: pointer;
  }

  li:hover {
    background-color: #ececec;
  }
}

.ListaDeMenu:hover {
  .ActivarMenuPins {
    display: block;
    z-index: 999;
  }
}

.ActivarMenuPins {
  display: none;
}

// Menu selecionado
.SubMenuActive {
  // a {
  //   width: 100%;
  //   color: #148f9f;
  // }

  li{
    width: 100%;
    color: #148f9f;
  }
}

//Menu sin seleccionar
.SubMenuNormal {
  a {
    width: 100%;
    color: #464646;
  }
}

// Drawer Cambiar de medico
.ListaDeMedicosDrawer {
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 1% 0.5%;
  border-bottom: 1px solid #d7d7d7;
  gap: 10px;
}

.ListaDeMedicosDrawer:hover {
  cursor: pointer;
  background-color: #ececec;
}

.NombreMedicoChange {
  font-weight: 500;
  width: 100%;
}

.BoxSadow {
  box-shadow: 0px 1px 15px 5px rgba(105, 106, 106, 0.25);
  -webkit-box-shadow: 0px 1px 15px 5px rgba(105, 106, 106, 0.25);
  -moz-box-shadow: 0px 1px 15px 5px rgba(105, 106, 106, 0.25);
}

@media (max-width: 800px) {
  .Menu-Hamburg {
    display: block;
  }

  .Menu_principal {
    display: none;
  }
}

li {
  color: #464646;

  a {
    color: #464646;
  }
}

@media (max-width: 375px) {
  .NombreMedicoChange {

    width: 110px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

  }
}