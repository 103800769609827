
// Tabla de citas
.GeneralContVincul{
    margin: .5% 0;
    .Hora{
      margin-left: 5%;
    }
    .Dia{
      font-size: 80%;
      color: #454545;
    }
    .statusImgv{
      width: 15px;
      img{
        border-radius: 30rem;
      }
    }
    .BgrImgv{
      img{
        background-color: #dde3e6;
        border-radius: 5rem;
        width: 40px;
        height: 40px;
      }
    }
  }
  .CardVincula{ 
      display: flex;
      width: 100%;
      align-items: center; 
      justify-content: space-between;   
  }
  .GeneralContVincul:hover{
    background-color: #dde3e6;
    .BgrImg{
      img{
        background-color: white;
      }
    }
  }
  
  .img2{
    width: 30px;
  }
  .ContainerVincula{
    padding: 1% 2%;
    background-color: white;
   }
   .ContainerVincula:hover{
    padding: 1% 2%;
    background-color: #f4f4f4;
    cursor: pointer;
   }
 
 