.contenedorprin {
  background-Color: white;
  margin: .5%;
  padding: 2%;
  width: 100%;
  height: 100%;
}

.Topmodaldetalle {
  display: flex;
  justify-content: space-between;
  background-color: #148f9f;
  color: white;
  padding: 1% 2%;

}

.contenidomodalA {
  text-align: center;
  width: 40vw;
  min-height: 40vh;
  overflow: hidden;
  overflow-y: scroll;
}

.contenidomodalA::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: #d6d6d6;
}

.contenidomodalA:hover::-webkit-scrollbar {
  display: initial;
}

.contenidomodalA::-webkit-scrollbar-thumb {
  background-color: rgb(96, 99, 102);
  border-radius: 1rem;
  height: 1px;
}

.contenidomodal {
  overflow: hidden;
  overflow-y: scroll;
  width: 40vw;
  max-height: calc(70vh - 52px);
}

.contenidomodal::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: #d6d6d6;
}

.contenidomodal:hover::-webkit-scrollbar {
  display: initial;
}

.contenidomodal::-webkit-scrollbar-thumb {
  background-color: rgb(96, 99, 102);
  border-radius: 1rem;
  height: 1px;
}

.tabsContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
}

.tabsMetodosPago {
  border: none;
  background-color: none;
  color: #424242;
  cursor: pointer;
  box-sizing: content-box;
  position: relative;
  outline: none;
  text-align: center;
  // height: 2rem;
  padding: 0 .5%;
  // font-weight: 600;
  font-size: 15px;
}

.tabsMetodosPago:hover {
  // background-color: #148f9f;
  color: #148f9f;
  // border-bottom: 2px solid #148f9f;
}

.active_tabs {
  background: #ffffff;
  border-bottom: 1px solid #148f9f;
  // font-weight: 500;
  color: #148f9f;
}

.BotonesStyle{
 display: flex;
 gap: .5rem;
 justify-content: space-between;
 align-items: center;
 color: white;
 background-color: #148f9f;
}

// Acomodo de tarjetas /////////////////////////////////////
.AliniarLeftMembresia{
  // float: left;
  width: 49%;
  background-color: white;
  padding: 2%;
  border-radius: .5rem;
}
.AliniarightMembresia{
  // float: right;
  width: 49%;
  background-color: white;
  padding: 2%;
  border-radius: .5rem;
}


@media (max-width: 800px) {
  .contenidomodal {
    width: 90vw;
  }

  .contenidomodalA {
    width: 90vw;
  }

// Acomodo de tarjetas /////////////////////////////////////
.AliniarLeftMembresia{
  margin: 10px 0;
  width: 100%;

}
.AliniarightMembresia{
  width: 100%;
  margin: 10px 0;
}

}

@media (max-width: 600px) {
  .tabsMetodosPago {
    width: 100%;
  }
}

@media (max-width: 380px) {
  .tabsMetodosPago {
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}