.contenedorRec{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    background-color: #d7d7d7;
    justify-content: center;
    align-items: center;
    padding: 3%;
 }
 
  .contenidoRec{
     display: block;
     padding: 1rem;
     background-color: white;
     border-radius: .1rem;
     overflow-y: auto;
     min-width: 30%;
     max-width: 60vw;
     min-height: 10vh;
     max-height: 90vh;
     margin: auto;
     
  }

  @media (max-width: 800px) {

   .contenidoRec{
     
      min-width: 30%;
      max-width: 90vw;
      min-height: 10vh;
      max-height: 90vh;
 
      
   }
}
 
 