.contenedorPrincipal {
  background-color: #eaeef0;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  word-break: break-all;
  padding: .5%;
}


.TablaEstudios{
    margin-top: .5%;
    min-height: calc(77vh - 52px);
    max-height: calc(83vh - 52px);
    overflow: hidden;
    overflow-y: scroll;
}

.DetalleEstudios{
  width: 35vw;
  min-height:60vh ;
   background-color: white;
}
.DetalleEstAnalisis{
  margin-top: .5%;
  // background-color: white;
  padding: .5%;
  height: calc(72vh - 52px);
  padding: 1%;
  overflow: hidden;
  overflow-y: scroll;
}

.botonesAcomodo{
  text-align: center;
  padding: 0 2% ;
  button{
    width: 100%;
    padding: 1%;
    background-color: #148f9f;
    color: #fff;
    border-radius: 5rem;  
  }
}

.SelectFantasma{
  padding: 3%; 
  width: 100%;
  border: 1px solid #d7d7d7; 
  border-radius: .2rem;
  background-color: white;
}
.SelectFantasmaActive{
  padding: 3%; 
  width: 100%;
  border: 1px solid #d7d7d7; 
  border-radius: .2rem;
  outline: 1px solid #00b7ff;
  box-shadow: 0 0 0 .2em #97b2c06c;
}
.DrowpOptions{
  border: 1px solid #d5d5d5;

}
.MenuOpDetalleEstudios{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2%;
  background-color: #148f9f;
  b{ color: #fff;}
}

//  scroll Tabla estudios
.TablaEstudios::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    background-color: #d6d6d6;
  }
  .TablaEstudios:hover::-webkit-scrollbar {
    display: initial;  
  }
  .TablaEstudios::-webkit-scrollbar-thumb {
    background-color: rgb(96, 99, 102);
    border-radius: 1rem;
   height: 1px;
  }
  //  scroll detalle
.DetalleEstAnalisis::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: #d6d6d6;
}
.DetalleEstAnalisis:hover::-webkit-scrollbar {
  display: initial;  
}
.DetalleEstAnalisis::-webkit-scrollbar-thumb {
  background-color: rgb(96, 99, 102);
  border-radius: 1rem;
 height: 1px;
}

.imgDetalleEstud{
  width: 80px;
  height: 80px;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  background-color: #d7d7d7;
  border-radius: 5rem;
  border: 2px solid #d7d7d7;
}
.ModalDetalEs{
  width: 35vw;
  
}

@media (max-width: 800px) {
  .ModalDetalEs{
    width: 90vw; 
  }
  .DetalleEstudios{
    width: 90vw;
  }
  .imgDetalleEstud{
    width: 50px;
    height: 50px;

  }
  .DetalleEstAnalisis{

    height: calc(60vh - 52px);


  }
}

.ImageNone{
  background-color: #d6d6d6;
background-image: url("../../Img/no-image.png");
background-repeat: no-repeat;
background-position: center; 
background-size: 90% 90%;

}
