
// Tabla de citas
.PrinCardrec{
  background-color: white;
  padding: 1%;
  margin: .5% 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .BgrImg{
    img{
      background-color: #dde3e6;
      border-radius: 5rem;
      width: 45px;
      height: 45px;
    }
  }
}

.cardRecExpe{
  background-color: white;
  padding: 1%;
  margin: .5% 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.menutrespuntosrec{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Containerrec2{
  display: flex;
  justify-content: space-between;
  .Opsiones{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    img{
      width: 20px;
      height: 20px;
    }
  }
}

.Cardrec{ 
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;
    margin: 1% 0;
    p{
      margin: 0;
      padding: 0;
    }
}
  
.PrinCardrec:hover{
  background-color: #dde3e6;
  .BgrImg{
    img{
      background-color: white;
    }
  }
}

.cardRecExpe:hover{
  background-color: #dde3e6;
}