*,
::before,
::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.GenaralScroll {
  overflow: hidden;
  overflow-y: scroll;
  height: calc(99vh - 52px);
}

// .GenaralScroll::-webkit-scrollbar {

//   width: 3px;
//   height: 3px;
//   background-color: #d6d6d6;
// }

// .GenaralScroll:hover::-webkit-scrollbar {
//   display: initial;
// }

// .GenaralScroll::-webkit-scrollbar-thumb {
//   background-color: rgb(96, 99, 102);
//   border-radius: 1rem;
//   height: 1px;
// }

.GridContactos {
  background-color: #eaeef0;
  padding: .5%;
  display: grid;
  grid-template-columns: 2.5fr 1fr;
  gap: 1%;
  height: calc(99vh - 52px);
  overflow: hidden;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: hidden;

}

.BotonNuevoContacto {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #148f9f;
  border-radius: .3rem;
  color: white;
  cursor: pointer;
  width: 100px;
  height: 40px;
}

// Modal de detalle
.ModalConsultasStyle {
  background-color: white;
  width: 40vw;
  height: 70vh;
  overflow: hidden;
  overflow-y: scroll;
  padding: 1% 2%;
}

.ModalConsultasStyle::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: #d6d6d6;
}

.ModalConsultasStyle:hover::-webkit-scrollbar {
  display: initial;
}

.ModalConsultasStyle::-webkit-scrollbar-thumb {
  background-color: rgb(96, 99, 102);
  border-radius: 1rem;
  height: 1px;
}

.TopNuevoCotacto {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  background-color: #148f9f;
  padding: 1% 2%;
  width: 100%;
}

.MidModalAddContracto {
  width: 50vw;
  height: 75vh;
  overflow: hidden;
  overflow-y: scroll;
  padding: 1%;
}

.MidModalAddContracto::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: #d6d6d6;
}

.MidModalAddContracto:hover::-webkit-scrollbar {
  display: initial;
}

.MidModalAddContracto::-webkit-scrollbar-thumb {
  background-color: rgb(96, 99, 102);
  border-radius: 1rem;
  height: 1px;
}

// ssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss
.encabezadodetallecontacto {
  display: flex;
  justify-content: space-between;
  padding: 4% 2%;
  align-items: center;
}

.imagencont {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border: 2px solid #d7d7d7;
  border-radius: 5rem;
  object-fit: cover;
  background: rgb(215, 215, 215);
  cursor: pointer;
}

.Encabezadocontacto {
  // display: fixed;
  background-color: #fff;
  padding: 1% 3%;
  width: 100%;
}

.encabezadadocont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #148f9f;
  padding: 0 2%;
  color: #fff;
}

.bloc-tabs {
  display: flex;
  width: 100%;
  margin-bottom: 1px;
}

hr {
  color: #8d0e0e;
}

.tabs {
  // padding: 1%;
  text-align: center;
  margin-top: .5%;
  border: none;
  background-color: white;
  color: #424242;
  padding: 0;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  cursor: pointer;
  box-sizing: content-box;
  position: relative;
  outline: none;
}

.active-tabs {
  background-color: white;
  color: #148f9f;
  border-bottom: 2px solid #148f9f;
}

.espaciado {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: .5%;
}



.GridContactos2 {
  padding: .5%;
  display: grid;
  grid-template-columns: 1fr;
}

.botones {
  display: flex;
  gap: 1%;
  padding: 0 3%;
  justify-content: left;
  width: 100%;
  background-color: white;
}

.contenido {
  width: 100%;
  height: calc(100vh - 52px);
  display: none;

}

.contenidoTemp {
  width: 100%;
  height: calc(100vh - 52px);
  display: block;
}

.active-contenido {
  display: block;
  height: calc(100vh - 52px);
}

// Detalle de info contacto
.contcont {
  background-color: #fff;
  height: 98vh;
  height: 80vh;
  background-color: "#fff";

}

.Detalleinfocont {
  max-height: calc(85vh - 52px);
  // min-height: calc(40vh - 52px);
  height: calc(60vh - 52px);
  overflow: hidden;
  overflow-y: scroll;
  padding: 1%;
  border-bottom: 1px solid #d7d7d7;
}

.botonesContacto {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    margin: 10px 0;
    width: 49%;
    border-radius: 5rem;
    padding: 1%;
    background-color: #148f9f;
    color: #fff;
  }

  .btn2cont {
    background-color: #db1c1c;
  }
}

// mensajes de no hay citas
.Contenido-tabs {
  .noContactos1 {
    text-align: center;
  }

  .noContactos2 {
    text-align: center;
  }
}

.Contenido-tabs {
  // height: 75%;
  // overflow: scroll;
  // overflow-x: hidden;
  background-color: #eaeef0;
}

// scroll
// .Contenido-tabs::-webkit-scrollbar {

//   width: 3px;
//   height: 3px;
//   background-color: #d6d6d6;
// }

// .Contenido-tabs:hover::-webkit-scrollbar {
//   display: initial;
// }

// .Contenido-tabs::-webkit-scrollbar-thumb {
//   background-color: rgb(96, 99, 102);
//   border-radius: 1rem;
//   height: 1px;

// }

.Detalleinfocont::-webkit-scrollbar {

  width: 3px;
  height: 3px;
  background-color: #d6d6d6;
}

.Detalleinfocont:hover::-webkit-scrollbar {
  display: initial;
}

.Detalleinfocont::-webkit-scrollbar-thumb {
  background-color: rgb(96, 99, 102);
  border-radius: 1rem;
  height: 1px;

}

@media (max-width: 800px) {
  .botones {
    justify-content: space-between;
  }

  .ModalConsultasStyle {
    width: 90vw;
    height: 75vh;
  }

  .MidModalAddContracto {
    width: 90vw;
    height: 75vh;
  }

}