.GenaralContListaMod{
    background-color: #eaeef0;
}
.ContenedorListaMod{
 padding: 1%;
 display: flex;
 align-items: center;
 justify-content: space-between;
 background-color: white;
 margin:.5% 0 0 .2%;
 border-bottom: 1px solid #d7d7d7;
 cursor: pointer;
}

.ContenedorListaMod:hover{
    background-color: #dde3e6;
    .BgrImg{
      img{
        background-color: white;
      }
    }
  }

.ImagPerfilListaMod{
    width: 40px; 
    height: 40px;
    border-radius: 5rem;
    margin-right: 1%;
    object-fit: cover;
    object-position: center;
    // overflow: hidden;
    background-color: #eaeef0;
}
@media (max-width: 800px) {
    .ImagPerfilListaMod{
        width: 35px; 
        height: 35px;
    }
}