.ContainerChat {
  background-color: #eaeef0;
  padding: 0.5%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5%;
}

.ContainerChat2 {
  padding: 0.5%;
  display: grid;
  grid-template-columns: 1fr;
}

.CabezeraChat {
  // width: 100%;
  background-color: white;
  padding: 1% 3%;

  .Encabesado-Chat {
    display: flex;
    justify-content: space-between;

    a {
      color: #148f9f;
      cursor: pointer;
    }
  }

  .Buscar {
    display: flex;
    align-items: center;
    width: 100%;
  }
}

// .texto {
//   word-wrap: break-word;
//   /* Ajustar el texto y romper palabras largas si es necesario */
//   max-height: 4em;
//   /* Altura máxima para el texto (4 líneas aproximadamente) */
//   overflow: hidden;
//   /* Ocultar el texto que se desborda de las 4 líneas */
// }

.mensaje .archivo img,
.mensaje .archivo video {
  max-width: 100%;
  /* Limita el ancho de las imágenes y videos al ancho del contenedor */
}

//Detalle de Chat
.imgDetalleChat {
  img {
    background-color: #e2e2e2;
    border-radius: 50px;
    width: 40px;
    height: 40px;
  }
}

.Chatmessage {
  width: 100%;
  margin-top: 80px;
  max-height: calc(57vh - 52px);
  min-height: 49vh;
  overflow: hidden;
  overflow-y: scroll;
  background-color: #fff;
  padding: 2%;

  .informacion {
    width: 100%;
    height: calc(52vh - 52px);
    overflow: hidden;
    overflow: scroll;
    overflow-x: hidden;
  }

  .Botones {
    text-align: center;
    margin-top: 3%;
  }
}

.Chatmessage::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: #d6d6d6;
}

.Chatmessage:hover::-webkit-scrollbar {
  display: initial;
}

.Chatmessage::-webkit-scrollbar-thumb {
  background-color: rgb(96, 99, 102);
  border-radius: 1rem;
}

/* seccion chat */
.seccion-chat {
  flex-basis: 0;
  flex-grow: 1;
}

.usuario-seleccionado {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 1% 2%;
  background: #148f9f;
  border-bottom: 1px solid #e7e7e7;
  position: fixed;
  color: white;
  z-index: 999;
  width: 60vw;
}

.usuario-seleccionado .cuerpo span {
  display: block;
  color: #fff;
}

.panel-escritura .textarea textarea,
.usuario-seleccionado .cuerpo {
  flex-grow: 1;
  flex-basis: 0;
}



/* mensaje */
.panel-chat {
  display: block;
  overflow-y: auto;
  height: 30rem;

  padding-left: 15px;
  padding-right: 0px;
  //margin-bottom: 1rem;
}

.mensaje {
  display: flex;
  flex-wrap: wrap;
  padding: 2%;
  width: 100%;
  //margin-bottom: 1rem;
  justify-content: flex-start;

  .cuerpo {
    font-size: 0.85em;
    padding: 6px;
    background-color: #dddcdc;
    border-radius: 8px;
    position: relative;
    max-width: calc(90vh - 52px);
    left: 10px;
    // text-align: justify;
  }

  .tiempo {
    margin-top: 2px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

}

.mensaje2 {
  display: flex;
  flex-wrap: wrap;
  padding: 2%;
  width: 100%;
  color: white;
  //margin-bottom: 1rem;
  // justify-content: flex-start;

  .cuerpo {
    font-size: 0.85em;
    padding: 6px;
    background-color: #148f9f;
    border-radius: 8px;
    position: relative;
    max-width: calc(90vh - 52px);
    right: 10px;
    // text-align: justify;
  }

  // .texto {
  //   display: block;
  //   position: relative;
  // }
  
  .tiempo {
    margin-top: 2px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.mensaje2.left {
  justify-content: flex-end;
}



/* panel escritura */

.panel-escritura button:active,
.usuario-seleccionado .opciones button:active,
.mensaje .opciones-msj button:active {
  background-color: #913333;
}

.panel-escritura {
  display: block;
  width: 100%;
}

.panel-escritura .textarea {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #e7e7e7;
  background-color: #eaeef0;
  padding: 1%;
  height: 70px;
}

.panel-escritura .textarea textarea,
.panel-escritura .opcines {
  margin-right: 0.5rem;
}

.panel-escritura button {
  border: 0;
  background-color: #e3e3e3;
  display: inline-flex;
  width: 35px;
  height: 35px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
}

.panel-escritura button label {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.panel-escritura button input {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.panel-escritura button.enviar {
  background-color: #148f9f;
  color: #fff;
  width: 30px;
  height: 30px;
}

.panel-escritura .textarea textarea {
  border: 0;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 10px;
}

// Modal de Prevew
.toolbar-wrapper {
  position: fixed;
  bottom: 32px;
  left: 50%;
  padding: 0px 24px;
  color: #fff;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  transform: translateX(-50%);
}

.toolbar-wrapper .anticon {
  padding: 12px;
  cursor: pointer;
}

.toolbar-wrapper .anticon[disabled] {
  cursor: not-allowed;
  opacity: 0.3;
}

.toolbar-wrapper .anticon:hover {
  opacity: 0.3;
}

.addRecetaChat {
  width: 100%;
  display: block;
  padding: 0 3%;
  margin-top: 100px;
  margin-bottom: 20px;
  // overflow: hidden;
  // overflow-y: scroll;
  // height: calc(80vh - 52px);
  text-align: center;

  p {
    margin: 0;
  }
}

.headerAddRecetaChat {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

// .addRecetaChat::-webkit-scrollbar {
//   width: 3px;
//   height: 3px;
//   background-color: #d6d6d6;
// }

// .addRecetaChat:hover::-webkit-scrollbar {
//   display: initial;
// }

// .addRecetaChat::-webkit-scrollbar-thumb {
//   background-color: rgb(96, 99, 102);
//   border-radius: 1rem;
//   height: 1px;
// }

@media (max-width: 800px) {
  .usuario-seleccionado {
    width: 90vw;
  }

  .Chatmessage {
    width: 90vw;
    margin-top: 60px;
  }

  .headerAddRecetaChat {
    justify-content: flex-end;
    display: block;
  }

  .mensaje{
    .cuerpo{
      margin-right: 70px;
    }
  }
  .mensaje2{
    
    .cuerpo{
      // width: 75%;
      margin-left: 70px;
    }
  }
}