// *, ::before, ::after {
//     box-sizing: border-box;
//     margin: 0;
//     padding: 0;
//   }

.GenaralScroll {
  overflow: hidden;
  overflow-y: scroll;
  max-height: calc(97vh - 52px);
}

.GenaralScroll::-webkit-scrollbar {

  width: 3px;
  height: 3px;
  background-color: #d6d6d6;
}

.GenaralScroll:hover::-webkit-scrollbar {
  display: initial;
}

.GenaralScroll::-webkit-scrollbar-thumb {
  background-color: rgb(96, 99, 102);
  border-radius: 1rem;
  height: 1px;
}

// Boton consulta nueva
.BotonNuevaConsult {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100px;
  background-color: #148f9f;
  border-radius: .3rem;
  cursor: pointer;
  color: white;
}

.imagencont {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border: 2px solid #d7d7d7;
  border-radius: 5rem;
  object-fit: cover;
  background: rgb(215, 215, 215);
  cursor: pointer;
}

.EncabezadoConsulta {
  // margin: .5%;
  background-color: white;
  padding: 1% 3%;
}


.ImgHistorialPerf {
  width: 60px;
  height: 60px;
  overflow: hidden;
  object-fit: cover;
  object-position: center;
  border-radius: 5rem;
}

.ImgMedicoSelect {
  width: 30px;
  height: 30px;
  overflow: hidden;
  object-fit: cover;
  object-position: center;
  border-radius: 5rem;
}

.Row {
  width: 100%;
  gap: 1%;
  display: grid;
  grid-template-columns: 1fr 1.5fr;
}

.GeneralContainerHistorial {
  padding: 1%;
  width: 100%;
  height: calc(99vh - 52px);
  overflow: hidden;
  overflow-y: scroll;
}

.GeneralContainerHistorial::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: #d6d6d6;
}

.GeneralContainerHistorial:hover::-webkit-scrollbar {
  display: initial;
}

.GeneralContainerHistorial::-webkit-scrollbar-thumb {
  background-color: rgb(96, 99, 102);
  border-radius: 1rem;
}

.Content {
  width: 100%;
}

.Historialflexible {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.BotonGuardarActive {
  display: none;
}


.ItemCard {
  background-color: white;
  border-bottom: 1px solid #d7d7d7;
  margin: 5px 0;
  height: 30px;

  :hover {
    background-color: #eeeeee;
    // cursor: pointer;
  }
}

.CardNotasMed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1%;
  padding-right: 1%;
  padding-bottom: 1%;

  img {
    width: 14px;
    cursor: pointer;
  }
}



// scroll
.ScrollConsultas {
  padding: 1%;
  width: 100%;
  height: calc(79vh - 52px);
  overflow: hidden;
  overflow-y: scroll;
}

.ScrollConsultas::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: #d6d6d6;
}

.ScrollConsultas:hover::-webkit-scrollbar {
  display: initial;
}

.ScrollConsultas::-webkit-scrollbar-thumb {
  background-color: rgb(96, 99, 102);
  border-radius: 1rem;
}

// scroll Notas medicas
.ScrollNotas {
  padding: 1%;
  width: 100%;
  height: calc(90vh - 52px);
  overflow: hidden;
  overflow-y: scroll;
}

.ScrollNotas::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: #d6d6d6;
}

.ScrollNotas:hover::-webkit-scrollbar {
  display: initial;
}

.ScrollNotas::-webkit-scrollbar-thumb {
  background-color: rgb(96, 99, 102);
  border-radius: 1rem;
}

// Detalle notas medicas
.scrolDetalleNotas {
  padding: 1%;
  width: 100%;
  height: calc(70vh - 52px);
  overflow: hidden;
  overflow-y: scroll;
}

.scrolDetalleNotas::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: #d6d6d6;
}

.scrolDetalleNotas:hover::-webkit-scrollbar {
  display: initial;
}

.scrolDetalleNotas::-webkit-scrollbar-thumb {
  background-color: rgb(96, 99, 102);
  border-radius: 1rem;
}

.scrolDatosConsulta {
  padding: 2%;
  width: 40vw;
  max-height: 50vh;
  overflow: hidden;
  overflow-y: scroll;
}

.scrolDatosConsulta::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: #d6d6d6;
}

.scrolDatosConsulta:hover::-webkit-scrollbar {
  display: initial;
}

.scrolDatosConsulta::-webkit-scrollbar-thumb {
  background-color: rgb(96, 99, 102);
  border-radius: 1rem;
}

.scrolResumenDeConsulta {
  padding: 2%;
  height: 72vh;
  width: 60vw;
  overflow: hidden;
  overflow-y: scroll;
}

.scrolResumenDeConsulta::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: #d6d6d6;
}

.scrolResumenDeConsulta:hover::-webkit-scrollbar {
  display: initial;
}

.scrolResumenDeConsulta::-webkit-scrollbar-thumb {
  background-color: rgb(96, 99, 102);
  border-radius: 1rem;
}

// Acordiones
.accordion-header {
  background-color: white;
}

.accordion-flush .accordion-item .accordion-button {
  background-color: white;
}

.accordion-button:hover {
  background-color: white;
  color: #424242;
}

.accordion-button.collapsed {
  background-color: rgb(255, 255, 255);
  color: #424242;
}

.configReceta {
  overflow: hidden;
  overflow-y: scroll;
  padding: 2%;
  height: 72vh;
  width: 40vw;

  p {
    margin: 0;
  }
}

.configReceta::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: #d6d6d6;
}

.configReceta:hover::-webkit-scrollbar {
  display: initial;
}

.configReceta::-webkit-scrollbar-thumb {
  background-color: rgb(96, 99, 102);
  border-radius: 1rem;
}



.sugerencia {
  padding: 5px;
}


.react-autosuggest__suggestions-container--open {
  display: block;
  // position: absolute;
  top: 31px;
  width: 100%;
  border: 1px solid #d9e2e4;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 100;
  font-size: 14px;
  border-radius: 4px;
  // border-bottom-right-radius: 4px;
  z-index: 2;
  max-height: 300px;
  overflow: auto;
  // margin-top: 16px;
  left: 0;
  right: 0;
  top: 40px;
  position: absolute;
}

.react-autosuggest__suggestions-list {
  list-style: none;
  margin-bottom: 0px;
  padding-left: 0px;
  // padding:0 1%;
  cursor: pointer;
  // position: absolute;
  background-color: #fff;
  width: 100%;
  // border: 1px solid #d9e2e4;
  // top: 10px;
  // right: 1rem;
}

.react-autosuggest__input {
  width: 100%;
  border: 1px solid #d9d9d9;
  color: #424242;
  border-radius: 6px;
  font-family: sans-serif;
  font-size: 14px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  position: relative;
}

.react-autosuggest__input:focus-visible {
  // outline: .5px solid #00b7ff;
  outline: none;
  border: 1px solid #039fdd;
  box-shadow: 0 0 0 .2em #c0dfee6c;
}

.react-autosuggest__input::placeholder {
  color: #d5d5d5;
}

.react-autosuggest__input:hover {
  border-color: #039fdd;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #f7f7f7;
}

// Styles de inputs normales
.InputNormal {
  width: 20%;
  border: 1px solid #ffffff;
  color: #424242;
  border-radius: 6px;
  font-family: sans-serif;
  font-size: 14px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  // position: relative;
}

.InputNormal:focus {
  // outline: .5px solid #00b7ff;
  outline: none;
  border: 1px solid #039fdd;
  box-shadow: 0 0 0 .2em #c0dfee6c;
}

.InputNormal::placeholder {
  color: #d7d7d7;
  font-weight: 50;
  font-size: small;
}

.InputNormal:hover {
  border-color: #a8bfc8;
}

// Modal prinsipal
.ModaleConsultaPrinsipal {
  position: relative;
  top: 0;
  left: 0;
  max-width: 100%;
  min-height: calc(100vh - 52px);
  background-color: rgba(0, 0, 0, 0.400);
  display: flex;
}

.ModaleConsultaPrinsipal-container {
  position: relative;
  background-color: white;
  border-radius: .1rem;
  overflow-y: auto;
  min-width: 30vw;
  max-width: 90vw;
  min-height: 10vh;
  max-height: 90vh;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2%;
  margin-bottom: auto;
}

.ModaleConsultaPrinsipal-Expecial {
  position: relative;
  background-color: white;
  border-radius: .1rem;
  overflow-y: auto;
  width: 35vw;
  height: 60vh;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2%;
  margin-bottom: auto;

  input {
    margin: 1% 0;
  }
}

//  css modal lista con scroll
.ContModalList {
  height: calc(39vh - 52px);
  overflow: hidden;
  overflow-y: scroll;
  border-top: 1px solid #d7d7d7;
}

.ContModalList::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: #d6d6d6;
}

.ContModalList:hover::-webkit-scrollbar {
  display: initial;
}

.ContModalList::-webkit-scrollbar-thumb {
  background-color: rgb(96, 99, 102);
  border-radius: 1rem;
  height: 1px;
}

//Costo de consultas
.scrolCostoConsulta {
  padding: 1%;
  width: 40vw;
  height: calc(40vh - 52px);
  overflow: hidden;
  overflow-y: scroll;
}

.scrolCostoConsulta::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: #d6d6d6;
}

.scrolCostoConsulta:hover::-webkit-scrollbar {
  display: initial;
}

.scrolCostoConsulta::-webkit-scrollbar-thumb {
  background-color: rgb(96, 99, 102);
  border-radius: 1rem;
  height: 1px;
}

// Detalle de estudios y analisis
.DetalleEsAna {
  padding: 1%;
  width: 40vw;
  height: calc(40vh - 52px);
  overflow: hidden;
  overflow-y: scroll;
}

.ListaDetalleEsAna {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 1%;
  border: 1px solid #d7d7d7;

  border-radius: .3rem;
}

.ListaDetalleEsAna:hover {
  background-color: #ececec;
  border: 1px solid #dae0ea;
}

// Modal lista de contactos
.ModalListContactosConsult {
  padding: 2%;
  width: 40vw;
}

//  css modal lista con scroll

.DetalleEsAna::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: #d6d6d6;
}

.DetalleEsAna:hover::-webkit-scrollbar {
  display: initial;
}

.DetalleEsAna::-webkit-scrollbar-thumb {
  background-color: rgb(96, 99, 102);
  border-radius: 1rem;
  height: 1px;
}

//  Modal detalle consultas
.ModalDetalConsuts {
  width: 45vw;
  height: 72vh;
  overflow: hidden;
  overflow-y: scroll;
}

.DisplayDetalle {
  display: flex;
}

.ModalDetalConsuts::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: #d6d6d6;
}

.ModalDetalConsuts:hover::-webkit-scrollbar {
  display: initial;
}

.ModalDetalConsuts::-webkit-scrollbar-thumb {
  background-color: rgb(96, 99, 102);
  border-radius: 1rem;
  height: 1px;
}

//Miodal Nuevo contacto consultas
.scrolNewContConsultas {
  width: 40vw;
  height: 72vh;
  overflow: hidden;
  overflow-y: scroll;
 
}

.scrolNewContConsultas::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: #d6d6d6;
}

.scrolNewContConsultas:hover::-webkit-scrollbar {
  display: initial;
}

.scrolNewContConsultas::-webkit-scrollbar-thumb {
  background-color: rgb(96, 99, 102);
  border-radius: 1rem;
  height: 1px;
}

.headerDateConsulta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.datepicker_consulta {
  width: 30%;
  // background: white;
}

.searchBarFiltroConsulta {
  display: flex;
  align-items: center;
  gap: 1%;
  background: white;
  input {
    margin-top: 5px;
    width: 45%;
  }
}

// Responsivo

@media (max-width: 800px) {
  .BotonGuardarActivation {
    display: none;
  }

  .BotonGuardarActive {
    display: block;
  }

  .Row {
    grid-template-columns: 1fr;
  }

  .Content {
    width: 100%;
  }

  .nav-link {
    font-size: 10px;

  }

  .Historialflexible {

    padding-right: 1%;
  }

  .ScrollConsultas {
    overflow: auto;
    height: 100%;
  }

  .ScrollNotas {
    overflow: auto;
    height: 100%;
  }

  .scrolDatosConsulta {
    width: 90vw;
    height: 37vh;
  }

  .scrolResumenDeConsulta {
    width: 90vw;
    height: 70vh;
  }

  .ModaleConsultaPrinsipal-Expecial {
    width: 95%;
    min-height: calc(80vh - 52px);
    max-height: calc(90vh - 52px);
  }

  .ModalListContactosConsult {
    width: 90vw;
  }

  .ModalDetalConsuts {
    width: 90vw;
  }

  .scrolDetalleConsults {
    height: 35vh;
  }

  .DisplayDetalle {
    display: block;
  }

  .configReceta {
    width: 97vw;
    height: 72vh;

  }

  .DetalleEsAna {
    width: 90vw;
    height: 30vh;

  }

  .scrolDetalleNotas {
    width: 90vw;
    height: 70vh;
  }

  .scrolCostoConsulta {
    width: 90vw;
    height: 30vh;
  }

  .scrolNewContConsultas {
    width: 90vw;
  }

  .datepicker_consulta {
    width: 30%;
    // background: white;
  }
}

@media (max-width: 600px) {
  .headerDateConsulta {
    display: block;
  }
  .searchBarFiltroConsulta {
    input {
      margin-top: 5px;
      width: 100%;
    }

    .rangeConsultasPrincipal {
      margin-top: 5px;
      width: 100%;
    }

    display: block;
    background: white;
  }
}

@media (max-width: 425px) {
  .datepicker_consulta {
    width: 40%;
    // background: white;
  }
}