.GridLicence {
  display: grid;
  text-align: center;
  justify-content: center;
  width: 100%;
  min-height: 90vh;
  gap: 1%;
  grid-template-columns: repeat(2, 1fr);
  padding: 1%;
}

.GridLicDiv {
  margin: .5%;
  width: 100%;
  padding: 2% 0;
  background-color: white;
  border-radius: .5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .BottonGrid {
    display: flex;
    justify-content: center;
    width: 95%;
    padding: 2%;
    background-color: #148f9f;
    color: #fff;
    border-radius: .3rem;
    margin-top: 10%;
    cursor: pointer;
  }

  .BottonGridDisabled {
    cursor: pointer;
    width: 90%;
    padding: 2%;
    background-color: grey;
    color: #000000;
    border-radius: .3rem;
    margin-top: 10%;
  }

  .SubTitleLic {
    color: #148f9f;
  }
}

// .GridLicDiv:hover {
//   margin: 0;
//   color: white;


//   background: rgb(9, 154, 219);
//   background: linear-gradient(180deg, rgb(9, 118, 219) 0%, rgba(20, 143, 159, 1) 100%);

//   .BottonGrid {
//     background-color: #ffffff;
//     color: #424242;
//     font-weight: 500;

//   }

//   .SubTitleLic {
//     color: white;
//     font-weight: 500;

//   }
// }

.GridLicDivBlue {
  // margin: .5%;
  width: 100%;
  padding: 2% 0;
  // background-color: white;
  border-radius: .5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  color: white;


  background: rgb(9, 154, 219);
  background: linear-gradient(180deg, rgb(9, 118, 219) 0%, rgba(20, 143, 159, 1) 100%);

  .BottonGrid {
    display: flex;
    justify-content: center;
    width: 95%;
    padding: 2%;
    background-color: #148f9f;
    color: #fff;
    border-radius: .3rem;
    margin-top: 10%;
    cursor: pointer;
  }

  .BottonGridDisabled {
    cursor: pointer;
    width: 90%;
    padding: 2%;
    background-color: grey;
    color: #000000;
    border-radius: .3rem;
    margin-top: 10%;
  }

  .SubTitleLic {
    color: white;
    font-weight: 500;

  }
}

.TextosLic {


  text-align: left;
  padding: 1%;
  width: 500px;

  p {
    display: flex;
    align-items: center;
    justify-content: left;
    margin: 1%;
    gap: 1%;
  }
}

.FormTarjetaLic {


  text-align: left;
  padding: 1%;
  width: 500px;
}

@media (max-width: 800px) {
  .GridLicence {
    grid-template-columns: repeat(1, 1fr);

  }

  .TextosLic {
    width: 100%;
  }
}