.ContenedorPrinAnunCard{
    background-color: white;
    padding: 1%;
    margin-bottom: .5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%
  }

  .ContenedorPrinAnunCard:hover{
    background-color: #dde3e6;
    .BgrImg{
      img{
        background-color: white;
      }
    }
  }
  
  .contenedormenuAnun{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .ContenedorCardAnun{ 
      display: flex;
      align-items: center;
      width: 100%;
      p{
        margin: 0;
        padding: 0;
      }
  }

  .BgrImg{
    img{
      background-color: #dde3e6;
      border-radius: 5rem;
      width: 45px;
      height: 45px;
    }
  }
    