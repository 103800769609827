@import './mixin.scss';

.title {
    font-size: 28px;
    font-weight: bold;
    color: #3c62a0;
}

.doctorName {
    font-size: 28px;
    color: #3c62a0;
    font-weight: 500;
}

.stadisticsContainer {
    justify-content: space-around;
    margin-top: 30px;
    padding: 0 8%;

    .chartContainer {
        @include media-query(480px) {
            margin-top: 30px;
        }
    }

    .firstContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        height: 175px;
        width: 265px;
        margin-left: 15px;
        margin-top: 10px;
        // margin-bottom: 10px;
        // background-color: #148f9f;
        background-color: #3c62a0;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        @include media-query(768px) {}

        @include media-query(480px) {}
    }

    .secondContainer {

        height: 195px;
        width: 265px;
        background-color: #55c7c8;
        // background-color: #3c62a0;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        @include media-query(768px) {}

        @include media-query(480px) {}

    }

    @include media-query(768px) {}

    @include media-query(480px) {}
}

.cardsContainer {
    display: flex;
    justify-content: space-around;
    height: 25vh;

    .firstCard {
        background-color: #3c62a0;
        height: 15vh;
        width: 35vw;
        text-align: center;
        color: #fff;
        border-radius: 8px;
        position: relative;
        overflow: hidden;

        @include media-query(2560px) {
            height: 15vh;
            width: 25vw;
        }

        @include media-query(1024px) {
            height: 15vh;
            width: 35vw;
        }

        @include media-query(768px) {
            height: 15vh;
            width: 45vw;
        }

        @include media-query(480px) {
            height: 15vh;
            width: 85vw;
        }

        .containerContent {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 15vh;

            img {
                width: 80px;

                @include media-query(2560px) {
                    width: 100px;
                }

                @include media-query(1024px) {
                    width: 80px;
                }

                @include media-query(768px) {
                    width: 80px;
                }

            }

            p {
                text-align: start;
                font-weight: 500;
                font-size: 18px;

                @include media-query(2560px) {
                    font-size: 22px;
                }

                @include media-query(1024px) {
                    font-size: 18px;
                }
            }
        }

        .gradient-top-left {
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 8px 0 0 0;
            clip-path: polygon(100% 50%, 100% 0%, 0% 0%, 0% 100%);
            background: #55c7c8;
            width: 25px;
            height: 7.5vh;

            @include media-query(768px) {}

            @include media-query(480px) {}
        }

        .gradient-bottom-right {
            position: absolute;
            bottom: 0;
            right: 0;
            border-radius: 0 0 8px 0;
            clip-path: polygon(0% 50%, 100% 0%, 100% 100%, 0% 100%);
            background: #55c7c8;
            width: 25px;
            height: 7.5vh;

            @include media-query(768px) {}

            @include media-query(480px) {}
        }


    }

    // .secondCard {
    //     background-color: #55c7c8;
    //     height: 15vh;
    //     width: 25vw;
    //     text-align: center;
    //     color: #fff;
    //     border-radius: 8px;
    //     position: relative;

    //     .gradient-top-left {
    //         position: absolute;
    //         top: 0;
    //         left: 0;
    //         border-radius: 8px 0 0 0;
    //         clip-path: polygon(100% 50%, 100% 0%, 0% 0%, 0% 100%);
    //         background: #3c62a0;
    //         width: 25px;
    //         height: 7.5vh;

    //         @include media-query(768px) {}

    //         @include media-query(480px) {}
    //     }

    //     .gradient-bottom-right {
    //         position: absolute;
    //         bottom: 0;
    //         right: 0;
    //         border-radius: 0 0 8px 0;
    //         clip-path: polygon(0% 50%, 100% 0%, 100% 100%, 0% 100%);
    //         background: #3c62a0;
    //         width: 25px;
    //         height: 7.5vh;

    //         @include media-query(768px) {}

    //         @include media-query(480px) {}
    //     }

    //     @include media-query(768px) {}

    //     @include media-query(480px) {}
    // }

    @include media-query(768px) {}

    @include media-query(480px) {}
}

.imgContainerResumen {
    width: 100%;
    height: 40vh;
    margin-top: 5vh;
    background-image: url('../../Img/home_view/f8403911-consultorio-medico-transformacion-digital.jpg');


    .colorBlurContainer {
        background-color: rgba(63, 125, 176, 0.5);
        width: 100%;
        height: 40vh;
        padding-left: 8%;
        padding-right: 8%;
        padding-top: 1%;
    }
}

.resumeContainer {
    width: 12%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 1px solid #3c62a0;
    cursor: pointer;

    @include media-query(1024px) {
        width: 14%;
    }

    @include media-query(768px) {
        width: 19%;
    }

    @include media-query(480px) {
        width: 27%;
    }

    .cardNumberContainer {
        background-color: #3c62a0;
        height: 12vh;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 3vw;
        font-weight: bold;

        @include media-query(2560px) {
            height: 12vh;
            font-size: 40px;
        }

        @include media-query(1024px) {
            font-size: 30px;
        }

        @include media-query(768px) {
            height: 12vh;
            font-size: 30px;
        }

        @include media-query(480px) {
            height: 8vh;
            font-size: 24px;
        }
    }

    .textModule {
        color: #3c62a0;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        height: 3vh;
        background-color: #fff;

        @include media-query(2560px) {
            height: 4vh;
            font-size: 18px;
        }

        @include media-query(1440px) {
            height: 3vh;
            font-size: 14px;
        }

        @include media-query(1024px) {
            font-size: 14px;
        }

        @include media-query(768px) {
            height: 3vh;
            font-size: 14px;
        }
    }

    .seeMoreContainer {
        background-color: rgba(63, 125, 176, 0.5);
        font-size: 13px;
        text-align: center;
        color: #3c62a0;
        border-top: 1px solid #3c62a0;
    }
}

.toolsContainer {
    justify-content: center;
    // padding-top: 20PX;
    width: 100%;

    @include media-query(768px) {
        width: 100%;
    }

    @include media-query(480px) {
        width: 100%;
    }

    .moduleCard {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 38%;
        height: 15vh;
        border-radius: 15px;
        color: #fff;
        padding-inline: 15px;
        margin-inline: 10px;

        @include media-query(1024px) {
            width: 40%;
            height: 18vh;
        }

        @include media-query(768px) {
            width: 45%;
            height: 20vh;
        }

        @include media-query(480px) {
            margin-top: 20px;
            width: 100%;
            height: 20vh;
            font-size: 12px;
        }
    }
}

// Primera fila de containers de herramientas
.first {
    margin-top: 20px;

    @include media-query(768px) {}

    @include media-query(480px) {}
}

// Segunda fila de containers de herramientas
.second {
    margin-top: 20px;

    @include media-query(768px) {}

    @include media-query(480px) {
        margin-top: 3px;
    }
}