.tabsContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  gap: 1.5rem;
}

.tabsAyuda {
  border: none;
  background-color: none;
  color: #424242;
  cursor: pointer;
  box-sizing: content-box;
  position: relative;
  outline: none;
  text-align: center;
  // height: 2rem;
  padding: 0 .5%;
  // font-weight: 600;
  font-size: 15px;
}

.tabsAyuda:hover {
  // background-color: #148f9f;
  color: #148f9f;
  // border-bottom: 2px solid #148f9f;
}

.active_tabs {
  background: #ffffff;
  border-bottom: 1px solid #148f9f;
  font-weight: 500;
  color: #148f9f;
}

.inputsearchtutoriales {
  width: 31%;
  // margin-bottom: 10px;
  // margin-left: 10px;
}

.soporteContent {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding: 0 10px;
}

.soporteSecondContent {
  width: 100%;
  background: white;
  padding: 10px;
  text-align: center;
}

@media (max-width: 800px) {
  .tabsAyuda {
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: none;
    p{
    width: 100%;
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    }
  }
}

@media (max-width: 600px) {
  .inputsearchtutoriales {
    width: 100%;
  }
}