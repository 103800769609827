.ContenedorCardConsul{
  margin: .1rem 0;
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  gap: 1%;
  padding: 1%;
  cursor: "pointer";
  p{
    margin: 0;
    padding: 0;
  }
}
.ImgConsultaCard{
 width: 40px;
 height: 40px;
 overflow: hidden;
 object-fit: cover;
 object-position: center;
 background-color: #d6d6d6;
 border-radius: 5rem;  
}
.ContenedorCardConsul:hover{
    background-color: #dde3e6;
    cursor: pointer;
}
