.topCloseModalNotas{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1% 2%;
  width: 100%;
  color: white;
  background-color: #148f9f;
}
.topCloseModalNotas1{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1% 2%;
    width: 100%;
    color: white;
    background-color: #148f9f;
  }
// Modal de detalle
  .ModalNotasMed{
    width: 50vw;
    height: 60vh;
    overflow: hidden;
    overflow-y: scroll;
    padding: 2%;
  }
  .ModalNotasMed::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    background-color: #d6d6d6;
  }
  .ModalNotasMed:hover::-webkit-scrollbar {
    display: initial;
  }
  .ModalNotasMed::-webkit-scrollbar-thumb {
    background-color: rgb(96, 99, 102);
    border-radius: 1rem;
    height: 1px;
  }
  // Modal de agregar
  .ModalNotasMed2{
    width: 40vw;
    height: 30vh;
    overflow: hidden;
    overflow-y: scroll;
    padding: 2%;
  }
  .ModalNotasMed2::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    background-color: #d6d6d6;
  }
  .ModalNotasMed2:hover::-webkit-scrollbar {
    display: initial;
  }
  .ModalNotasMed2::-webkit-scrollbar-thumb {
    background-color: rgb(96, 99, 102);
    border-radius: 1rem;
    height: 1px;
  }
  //Modal Notas Resumen Medico
  .ModalNotasResumenMed{
    width: 40vw;
    height: 70vh;
    overflow: hidden;
    overflow-y: scroll;
    padding: 2%;
  }
  .ModalNotasResumenMed::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    background-color: #d6d6d6;
  }
  .ModalNotasResumenMed:hover::-webkit-scrollbar {
    display: initial;
  }
  .ModalNotasResumenMed::-webkit-scrollbar-thumb {
    background-color: rgb(96, 99, 102);
    border-radius: 1rem;
    height: 1px;
  }
  

//   boton resumen medico
.Marg-Aline-BotonResumen{
    width: 100%;
    display: flex;
    justify-content: right;
}
.BotonResumen{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3%;
    border-radius: .3rem;
    color: white;
    background-color: #148f9f;
    padding: 1%;
    width: 25%;
    cursor: pointer;
    img{
        width: 16px;
        height: 16px;
    }
}

// Acordiones Notas medicas
.ExploAcordNotas{
  display: flex;
  width: 100%;
}
.InputNormal{
  width: 100%;
 }


@media (max-width: 800px) {

  .ModalNotasMed{
    width: 90vw;
    height: 70vh;
    overflow: hidden;
    overflow-y: scroll;
    padding: 2%;
  } 
  .ExploAcordNotas{
    display: block;
  }

  .InputNormal{
   display: block;
   width: 100%;
  }
  .ModalNotasMed2{
    width: 90vw;
  }
  .ModalNotasResumenMed{
    width: 90vw;
  }

}
@media (max-width: 320px) {
  .BotonResumen{
    width: 35%;
    padding: 1% 2%;
}
}