// Tabla de citas
.GeneralcontainCitasdd{
  max-width: 100%;
  max-height: calc(59vh - 52px);
  margin-bottom: 10px;
  overflow: hidden;
  overflow-y: scroll;
  background-color:#eaeef0;
  
}

.PrinCardCitasdd{
    background-color: white;
    cursor: pointer;
    border-bottom: 1px solid #d5d5d5;
    margin: .1rem;
    .BgrImgCitasdd{
      img{
        background-color: #dde3e6;
        border-radius: 5rem;
        width: 45px;
        height: 45px;
      }
    }
  }
  .CardCitasdd{ 
      display: flex;
      width: 100%;
      align-items: center;
      gap: 1%;
      p{
        margin-right: 30px;
      }
      
  }
  .PrinCardCitasdd:hover{
    background-color: #dde3e6;
    .BgrImg{
      img{
        background-color: white;
      }
    }
  }
  .ContainerCitasdd{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1% 2%;
   }

  //  Scroll General component
  .GeneralcontainCitasdd::-webkit-scrollbar {
    
    width: 3px;
    height: 3px;
    background-color: #d6d6d6;
  }
  .GeneralcontainCitasdd:hover::-webkit-scrollbar {
    display: initial;  
  }
  .GeneralcontainCitasdd::-webkit-scrollbar-thumb {
    background-color: rgb(96, 99, 102);
    border-radius: 1rem;
   height: 1px;
  }