.Reg {
    background-color: #d7d7d7;
    width: 100%;
    padding: 2%;
    height: 100vh;

    .RegMargen {
        max-height: 90vh;
        overflow: scroll;
        overflow-x: hidden;
        padding: 3%;
        background-color: white;
    }

    .lgo {
        width: 100px;
        margin-bottom: 3%;
    }

    p,
    h5,
    h3 {
        text-align: left;
        margin-bottom: 1%;
    }

    ul {
        list-style: none;
    }

    Input {
        width: 100%;
        height: 40px;
    }

    .form {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        background-color: white;
    }
}



.inputblue {
    border: 1px solid #d7d7d7;
    color: #929292;
    border-radius: .2rem;
    option {
        color: #000000;
    }

    option:hover {
        background-color: #148f9f;
    }

}

.inputblue:focus-visible {
    outline: 1px solid #00b7ff;
    box-shadow: 0 0 0 .2em #97b2c06c;
}

// Terminos Activar modal
.Activar {
    cursor: pointer;
}

.Activar:hover {
    color: #148f9f;
}

// Css scrolles
.RegMargen::-webkit-scrollbar {

    width: 3px;
    height: 3px;
    background-color: #adadad;
}

.RegMargen:hover::-webkit-scrollbar {
    display: initial;
}

.RegMargen::-webkit-scrollbar-thumb {
    background-color: rgb(96, 99, 102);
    border-radius: 1rem;
    height: 1px;
}

// Modal de teminos y condiciones
.ModalTerminos{
    width: 50vw;
    height:70vh;
    overflow: hidden;
    overflow-y: scroll;
    background-color: #edf0f2;
    padding: 1%;
}
.ModalTerminos::-webkit-scrollbar {

    width: 3px;
    height: 3px;
    background-color: #d6d6d6;
  }
  
  .ModalTerminos:hover::-webkit-scrollbar {
    display: initial;
  }
  
  .ModalTerminos::-webkit-scrollbar-thumb {
    background-color: rgb(96, 99, 102);
    border-radius: 1rem;
    height: 1px;
  
  }
.ModalRContainer{
background-color: white;
padding: 2%;
border-radius: .5rem;
}

.ModalCorreoR{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40vw;
    max-height: calc(72vh - 52px);
    padding: 3%;
    overflow: hidden;
    overflow-y: scroll;
}
.ModalCorreoR::-webkit-scrollbar {

    width: 3px;
    height: 3px;
    background-color: #adadad;
}

.ModalCorreoR:hover::-webkit-scrollbar {
    display: initial;
}

.ModalCorreoR::-webkit-scrollbar-thumb {
    background-color: rgb(96, 99, 102);
    border-radius: 1rem;
    height: 1px;
}

@media (max-width: 800px) {
    .ModalTerminos{
        width: 90vw;
        height: 72vh;
    }
  
    .ModalCorreoR{
        width: 90vw;
        height: 50vh;
       
    }
}