.ContenedorListaAsistente{
 padding: 1%;
 margin-bottom: 1%;
 display: flex;
 align-items: center;
 justify-content: space-between;
 background-color: white;
}
.ContenedorListaAsistente:hover{
    background-color: #eaeef0;
    cursor: pointer;
    border-bottom: 1px solid #d7d7d7;
   }

.ImagPerfilListaAsist{
    width: 40px; 
    height: 40px;
    border-radius: 5rem;
    margin-right: 1%;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
    background-color: #eaeef0;
}