.PerfilCardContainer{
    width: 300px;
    display: flex; 
    align-items: center;
    gap: 1%;
    margin: .5% 0;
    justify-content: space-between; 
    background-color: #ededed;
    padding: 1% 3%;
    i{
        cursor: pointer;
        img{
            width: 15px;
            cursor: pointer;

        }
    }
}
@media (max-width: 800px) {
.PerfilCardContainer{
    width: 100%;
}
}