// Tabla de citas
.PrinCardcitas {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  margin-bottom: 3px;
  padding: .5% 1%;


  .statusImg {
    width: 20px;
    height: 20px;

    img {
      border-radius: 30rem;
    }

  }

  .BgrImg {
    background-color: #dde3e6;
    border-radius: 5rem;
    width: 50px;
    height: 45px;
  }
}


.Containercitas2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.CardC {
  display: flex;
  align-items: center;
  gap: 3%;
  width: 95%;

  p {
    margin: 0;
  }

}

.PrinCardcitas:hover {
  background-color: #dde3e6;

  .BgrImg {
    img {
      background-color: white;
    }
  }
}

// Tabla de citas 2
.PrinCardcitas2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  margin-bottom: 3px;
  padding: .5% 1%;


  .statusImg {
    width: 20px;
    height: 20px;

    img {
      border-radius: 30rem;
      width: 20px;
      height: 20px;
    }

  }

  .BgrImg {
    background-color: #dde3e6;
    border-radius: 5rem;
    width: 50px;
    height: 45px;
  }
}


.Containercitas2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.CardC {
  display: flex;
  align-items: center;
  gap: 3%;
  width: 95%;

  p {
    margin: 0;
  }

}
.DespleListaExCitas ul {
  display: none;
}

.DespleListaExCitas:hover ul {
  display: block;

  li:hover {
    background-color: #dde3e6;
  }
}

.img2 {
  width: 30px;
}

.menucitas {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
}



@media (max-width: 767px) {
  .PrinCardcitas {
    .BgrImg {
      background-color: #dde3e6;
      border-radius: 5rem;
      width: 55px;
      height: 50px;
    }
  }

}