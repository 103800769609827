.box {
    padding: 1rem;
    margin: 1rem;
    box-shadow: 0 0 1rem #00000025 inset;
}

.grid-Responsive {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: .5rem;
}

.tutorialesContent {
    width: 100%;
    padding: 1%;
}

.videosResponsive{
  width: 100%;
  display: flex;
  justify-content: left;
  gap: 1rem;  
}

@media (max-width: 608px) {
    .videosResponsive{
        justify-content: center;
      }
    .tutorialesContent {
        display: flex;
        justify-content: center;
    }
}