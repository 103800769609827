// .notiCard{
 
// }

.ContenidoNotiCardRead{
  width: 100%;
  display: flex;
  // justify-content: space-between;
  align-items: center;
  padding: 1% 2%;
  border-bottom: 1px solid #d7d7d7;
  gap: 1rem;
}
.ContenidoNotiCardNoRead{
  background-color: #dadada;
  width: 100%;
  display: flex;
  // justify-content: space-between;
  align-items: center;
  padding: 1% 2%;
  border-bottom: 1px solid #d7d7d7;
  gap: 1rem;
  cursor: pointer;
}
.ContenidoNotiCardRead:hover{
  background-color: #eaeef0;
  cursor: pointer;
}

.ContenidoSoliCard{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1% 2%;
  border-bottom: 1px solid #d7d7d7;
}
.ContenidoSoliCard:hover{
  background-color: #eaeef0;
 
}


.imgNotiCard{
  width: 40px;
  height: 40px;;
  border-radius: 5rem;
  object-fit: cover;
  object-position: center;
}

.TopModal{
    width: 100%;
    color: white;
    background-color: #148f9f;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1% 2%;
  }
.DetalModalNotif{
    padding: 2%;
    width: 60vw;
    background-color: white;
    height: 60vh;
    overflow: hidden;
    overflow-y: scroll;
  }
  .DetalModalNotif::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    background-color: #d6d6d6;
  }
  
  .DetalModalNotif:hover::-webkit-scrollbar {
    display: initial;
  }
  
  .DetalModalNotif::-webkit-scrollbar-thumb {
    background-color: rgb(96, 99, 102);
    border-radius: 1rem;
    height: 1px;
  }

  @media (max-width: 800px) {
    .DetalModalNotif{
      width: 90vw;
    }
    // .ContenidoSoliCard{
      // display: block;
    // }
  }
