.modal2{
   position: fixed;
   z-index: 999;
   top: 0;
   left: 0;
   width: 100%;
   min-height: 100vh;
   background-color: rgba(0, 0, 0, 0.445);
   display: none;
   justify-content: center;
   align-items: center;
   
}
.modal.is-open{
    display: flex;
}
 .modal-container2{
    position: relative;
    padding: 1rem;
    background-color: #f2f4f5;
    border-radius: .1rem;
    overflow-y: auto;
    min-width: 35%;
    max-width: 40%;
    min-height: 10vh;
    max-height: 90vh;
 }
 .modal-close2{
    position: absolute;
    width: 2rem;
    top: 10px;
    right: 1rem;
    padding: .5%;
    border-radius: .5rem;
    color: gray;
    background-color: #eaeef0;
 }
 .modal-close:hover{
    background-color: #148f9f;

 }
