.ContainerExpedientes{
    background-color: #eaeef0;
    padding: .1% 0;
    // height: 100%;
    height: calc(100vh - 52px);
    overflow: hidden;
    overflow-y: scroll;
}
.CebezeraExpedientes{
    background-color: white;
    padding: 1% 3%;
    .Encabesado-Expedientes{
        display: flex;
        justify-content: space-between;
        a{
        color: #148f9f;
        cursor: pointer;
     }   
}
.Buscar{
    display: flex;
    align-items: center;
    width: 100%;
}
}
.imgDetalleEstud{
  width: 40px;
  height: 40px;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  background-color: #d7d7d7;
  border-radius: 5rem;
  border: 2px solid #d7d7d7;
}

// Aqui empieza la tabla
.contenido-Expedientes{
    background-color: #eaeef0;
    width: 100%;
    max-height: 72vh;
    overflow: hidden;
    overflow: scroll;
    overflow-x: hidden;
    }
//Componentes de detalle
.InformacionEx{
    margin-top: .5%;
    background-color: white;
    padding: .5%;
    height: calc(98vh - 52px);
    padding: 1%;
    overflow: hidden;
}







// Css scrolles detalle
.ContainerExpedientes::-webkit-scrollbar {
    
    width: 3px;
    height: 3px;
    background-color: #d6d6d6;
  }
  .ContainerExpedientes:hover::-webkit-scrollbar {
    display: initial;  
  }
  .ContainerExpedientes::-webkit-scrollbar-thumb {
    background-color: rgb(96, 99, 102);
    border-radius: 1rem;
   height: 1px;
  }
//   scroll formFicha
  .formFicha::-webkit-scrollbar {
    
    width: 3px;
    height: 3px;
    background-color: #d6d6d6;
  }
  .formFicha:hover::-webkit-scrollbar {
    display: initial;  
  }
  .formFicha::-webkit-scrollbar-thumb {
    background-color: rgb(96, 99, 102);
    border-radius: 1rem;
   height: 1px;
  }

  //Modal Add NUevo
  .ExpediTop{
    width: 100%;
    display: flex;
    padding: 1% 2%;
    text-align: center;
    justify-content: space-between;
    align-items: center;
    color: white;
    background-color: #148f9f;
  }
  .ExpediContAddNew{
    padding: 2%;
    width: 40vw;
    b{
      font-size: 95%;
      font-weight: 500;
    }
  }
  @media (max-width: 800px) {
    .ExpediContAddNew{
      width: 90vw;
      
    }
  }