// .Login {
//     width: 100%;
//     height: 100vh;

//     .lgo {
//         width: 100px;
//         margin-bottom: 3%;
//     }

//     p,
//     h5,
//     h3 {
//         text-align: left;
//         margin-bottom: 1%;
//     }
// }

.div-email {
    padding-top: 50px;
}

.div-password {
    padding-top: 20px;
}

// .Aline-left {
//     padding: 6%;
//     background-color: hsl(223, 47%, 97%);
//     height: 100vh;
//     width: 50%;
//     float: left;

//     .lgo {
//         width: 100px;
//         margin-bottom: 3%;
//     }

//     p,
//     h5,
//     h3 {
//         text-align: left;
//         margin-bottom: 1%;
//     }
// }

// .Aline-right {
//     // background-color: #148f9f;
//     background-image: linear-gradient(45deg, #148F9F 0%, #3B7DB2 100%);
//     height: 100vh;
//     width: 50%;
//     float: right;
// }

.InputLogin {
    margin-top: 5px;
    width: 100%;
    height: 45px;
    // border-radius: 15px;
}


// .Login label {
//     font-size: 14px;
//     color: #3B3B3B;
// }

// .Login ::placeholder {
//     font-size: 14px;
//     color: #3B3B3B;
// }

// .Aline-left label {
//     font-size: 14px;
//     color: #3B3B3B;
// }

// .Aline-left ::placeholder {
//     font-size: 14px;
//     color: #3B3B3B;
// }

.colLeft label {
    font-size: 14px;
    color: #3B3B3B;
}

.colLeft ::placeholder {
    font-size: 14px;
    color: #3B3B3B;
}

.BotonAlineLogin {
    padding-top: 30px;
    // text-align: center;
    width: 100%;

    .buttonLogin {
        padding-top: 30px;
        width: 100%;
        height: 45px;
        border-radius: 15px;
        background-color: #148F9F;
        color: white;
        font-size: 16px;
        font-weight: 700;
        border: none;
        // cursor: pointer;
    }


}

.buttonLogin {
    // padding-top: 30px;
    width: 100%;
    height: 45px;
    border-radius: 15px;
    background-color: #148F9F;
    color: white;
    font-size: 16px;
    font-weight: 700;
    border: none;
    // cursor: pointer;
}

.other-options {
    padding-top: 30px;
}

.div-rigth {
    padding-top: 20px;
    padding-bottom: 20px;
    float: right;
}


.HCentrado {
    padding-top: 15px;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
}

.other-options-button-link {
    font-size: 14px;
    color: #148F9F;
    text-decoration: none;
}

.carrusel {
    height: 100vh;
    // min-height: 100vh;
    // max-height: calc(99vh - 52px);
}

.colLeft {
    padding: 6%;
    background-color: hsl(223, 47%, 97%);
    // height: 100vh;
    width: 50%;

    // float: left;
    .lgo {
        width: 100px;
        margin-bottom: 3%;
    }

    p,
    h5,
    h3 {
        text-align: left;
        margin-bottom: 1%;
    }

    span {
        font-size: 14px;
        // color: #3B3B3B;
        padding-right: 5px;
    }
}

.colRight {
    background-image: linear-gradient(45deg, #148F9F 0%, #3B7DB2 100%);
    // height: 100vh;
    width: 50%;
    // float: right;
}



@media (max-width: 800px) {
    // .Aline-right {
    //     display: none;
    // }

    // .Aline-left {
    //     width: 100%;

    // }

    .colRight {
        display: none;
    }

    .colLeft {
        width: 100%;
        height: 100vh;
    }

    .BotonAlineLogin {

        text-align: center;

    }

    .HCentrado {
        display: flex;
        justify-content: center;
    }
}