.GeneralContainerFacturas{
    padding: .5%;
}

.TablaFacturacion{
  height: calc(80vh - 50px);
  width: 100%;
  overflow: hidden;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-top: .5%;
}
// Css scrolles
.TablaFacturacion::-webkit-scrollbar {
    
    width: 3px;
    height: 3px;
    background-color: #adadad;
  }
  .TablaFacturacion:hover::-webkit-scrollbar {
    display: initial;  
  }
  .TablaFacturacion::-webkit-scrollbar-thumb {
    background-color: rgb(96, 99, 102);
    border-radius: 1rem;
   height: 1px;
  }
