.ContenedorCardAsist{
    background-color:#fff; 
    margin-bottom: .5% ;
    padding: 1%;
}

.AcomodoCardAsist{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ImgPerfAsist{
    width: 40px; 
    height: 40px;
    border-radius: 5rem;
    margin-right: 1%;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
    background-color: #eaeef0;
}
.ImgPerfAsist:hover{
  background-color: #fff;
}
.DespliegeAsistentCard{
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 2%;
}
.ContenedorCardAsist:hover{
    padding: 1%; 
    background-color: #eaeef0;
}
