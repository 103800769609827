.ContenedorPrincipalCard{
  background-color: white;
  padding:.5% 1%;
  margin: .5% 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.ContenedorPrincipalCard:hover{
  background-color: #dde3e6;
  cursor: pointer;
  .BgrImg{
    img{
      background-color: white;
    }
  }
}
    
.BgrImg{
  img{
    background-color: #dde3e6;
    border-radius: 5rem;
    width: 45px;
    height: 45px;
  }
}

  .Cardcontactos{ 
      display: flex;
      width: 95%;
      align-items: center;
      margin: 1% 0;
      gap: 1%;
      p{
        margin-right: 30px;
      }
      
  }
  
  .img2{
    width: 30px;
  }
  .ContenedorContacto{
    width: 95%;
    display: flex;
    justify-content: space-between;
    
    .Opsiones{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
      img{
        width: 20px;
        height: 20px;
      }
    }
    
   }
  
  .active{
    background: "#000";
  }

  .TarjetaPincipal{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: "pointer"
    // margin-right: ;
  }

  .DespleListaExCont ul{
    display: none;
  }
  .DespleListaExCont:hover ul{
    display: block;
   
    li:hover{
      background-color: #dde3e6;
    } 
  }

  .listacontop{
    background-color: white;
    position: absolute;
    z-index: 999;
    border: 1px solid #d7d7d7;
    

    li{
      padding: 1%;
      width: 100%;
      list-style-type: none;
    }
  }