
// Tabla de citas
.PrinCardv{
    background-color: white;
    margin-top: .2%;
    border-bottom: 1px solid #d5d5d5;
    cursor: pointer;
    .Hora{
      margin-left: 5%;
    }
    .Dia{
      font-size: 80%;
      color: #454545;
    }
    .statusImgv{
      width: 15px;
      img{
        border-radius: 30rem;
      }
    }
    .BgrImgv{
      display: flex;
      align-items: center;
      width: 100%;
      gap: 2%;
      img{
        background-color: #dde3e6;
        border-radius: 5rem;
        width: 45px;
        height: 45px;
      }
    }
  }
  .Cardv{ 
      display: flex;
      width:100%;
      align-items: center;
      margin-top: .5%;
      padding: 1% 2%;
      justify-content: space-between;
      p{
        margin-right: 30px;
      }
      
  }
  .PrinCardv:hover{
    background-color: #dde3e6;
    .BgrImg{
      img{
        background-color: white;
      }
    }
  }
  
  .img2{
    width: 30px;
  }
  .Containerv{
    display: flex;
    justify-content: space-between;
 
   }
  
 