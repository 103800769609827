.content1{  
  background-color: #eaeef0;
  width: 100%;
}

// body {
//   width: 100vw;
//   // height: calc(100vh - 52px);  
// }



.flex{
  display: flex;
}
.conteOutlet{
  width: 100%;
  // overflow-y: scroll;
}

.conteOutlet::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: #d6d6d6;
}
.conteOutlet:hover::-webkit-scrollbar {
  display: initial;
}
.conteOutlet::-webkit-scrollbar-thumb {
  background-color: rgb(96, 99, 102);
  border-radius: 1rem;
  height: 1px;
}


input{
  margin-top: 3px;
  height: 35px;
  width: 100%;
  padding-left: 1%;
}
.img-perfil{
  width: 40px;
  height: 40px;
  overflow: hidden;
  border: 2px solid #d7d7d7;
  border-radius: 5rem;
  object-fit: cover;
  background: rgb(215, 215, 215);
  cursor: pointer;
}
