
.contenedorprinIyE{
  background-color: #eaeef0;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  word-break: break-all;
  padding: .5%;
}

.EncabezadoIyE{
    background-color: #fff;
    width: 100%;
    padding: 1% 3%;
    .contIyE{
      padding: 1%;
      text-align: center;
      width: 15%;
      cursor: pointer;
      box-sizing: content-box;
      position: relative;
      outline: none;
    }
    .active-contIyE{
      background: #ffffff;
      color: #148f9f;
    }
}

.espaciadoIyE{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1%;
}

.IngyEgrecard{
  max-height: calc(78vh - 52px);
  overflow: hidden;
  overflow-y: scroll;
  margin-top: .5%;
}

.IngyEgrecard::-webkit-scrollbar {
    
  width: 3px;
  height: 3px;
  background-color: #d6d6d6;
}
.IngyEgrecard:hover::-webkit-scrollbar {
  display: initial;  
}
.IngyEgrecard::-webkit-scrollbar-thumb {
  background-color: rgb(96, 99, 102);
  border-radius: 1rem;
 height: 1px;
}

*, ::before, ::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  .TopdetailIyE{
    display: flex;
    justify-content: space-between;
    background-color: #148f9f;
    padding: 1% 2%;
    span {
      color: white;
    }
  }

  .Contenedormodal{
    text-align: center;
    width: 35vw;
    min-height: calc(70vh - 52px);
    overflow: hidden;
    overflow-y: scroll;
    
    p{
      margin: 0;
    }
  }
  .Contenedormodal::-webkit-scrollbar {
    
    width: 3px;
    height: 3px;
    background-color: #d6d6d6;
  }
  .Contenedormodal:hover::-webkit-scrollbar {
    display: initial;  
  }
  .Contenedormodal::-webkit-scrollbar-thumb {
    background-color: rgb(96, 99, 102);
    border-radius: 1rem;
   height: 1px;
  }

  @media (max-width: 800px) {
    .Contenedormodal{
      width: 90vw;
    }
  }